import React from 'react';
import { Field } from 'formik';
import * as Yup from 'yup';
import { Input, InputFile, SelectField, TextArea, TextAreaEditor, DatePickerField, TreeSelectField, TreeSelectFieldMulti } from '../_partials/controls';
import { buildSelectData } from './SelectHelpers';
import { nestedNoTree, treeCustomRelatedBuild } from './TreeVirHelpers';

export function buildDataCRUD (itemObj){
  let itemProps = Object.keys(itemObj);
  return itemProps.reduce((obj,item) => {
    let datai = itemObj[item];
    if(Array.isArray(datai)) {
      //nếu không phải là pid
      if(item !== "pid"){
        obj[item] = datai.reduce((arr,itemChild) => {
          if(typeof itemChild === 'object') {
            if(itemChild instanceof Date || datai instanceof File || datai instanceof Blob){
              arr.push(itemChild);
            }else{
              arr.push(itemChild._cid);
            }
          }else{
            arr.push(itemChild);
          }
          return arr;
        },[]);
      }else{
        //vì dùng tree select nên phải có cái này
        obj[item] = datai.length >=1 ? datai.pop().value : ""
      }
    }else if(datai !== null && typeof datai === 'object') {
      if(datai instanceof Date || datai instanceof File || datai instanceof Blob){
        obj[item] = datai
      }else{
        obj[item] = datai._cid
      }
    }else{
      obj[item] = datai
    }
    return obj;
  },{});
}

export function FormHelpers(intl, uuidKeyCode, isEditForm, relatedEntities, UIProps = { mappingProps: '###' }) {
  // Validation schema
  const buildValidateFromAppModuleConfigProps = propItem => {
    let itemValidate = Yup;
    if (propItem.typeControl === 'DATEPICKER') {
      itemValidate = itemValidate.mixed();
    } else {
      itemValidate = itemValidate.string();
    }
    itemValidate = itemValidate
      .nullable(false)
      .required(intl.formatMessage({ id: 'GENERAL.VALIDATION.REQUIRE_FIELD' }, { name: propItem.text }));
    //validate
    return propItem.validate.reduce((obj, item) => {
      //min
      if (item.type === 'MIN') {
        return obj.min(
          item.min ? item.min : 3,
          intl.formatMessage({ id: 'GENERAL.VALIDATION.MIN_LENGTH_FIELD' }, { min: item.min ? item.min : 3 })
        );
      }
      //max
      if (item.type === 'MAX') {
        return obj.max(
          item.max ? item.max : 50,
          intl.formatMessage({ id: 'GENERAL.VALIDATION.MAX_LENGTH_FIELD' }, { max: item.max ? item.max : 50 })
        );
      }
      //email
      if (item.type === 'EMAIL') {
        return obj.email(intl.formatMessage({ id: 'GENERAL.VALIDATION.EMAIL_FIELD' }, { name: propItem.text }));
      }
      //require
      if (item.type === 'REQUIRE') {
        if (propItem.typeControl === 'DATEPICKER') {
          return obj.nullable(false).required(intl.formatMessage({ id: 'GENERAL.VALIDATION.REQUIRE_FIELD' }, { name: propItem.text }));
        } else {
          return obj.required(intl.formatMessage({ id: 'GENERAL.VALIDATION.REQUIRE_FIELD' }, { name: propItem.text }));
        }
      }
      return obj;
    }, itemValidate);
  };

  const buildField = (itemProp, asteriskSign, gridNumField) => {
    // let disableField = isEditForm && !!itemProp.disableWhenUpdate;
    let disableField = (isEditForm && !!itemProp.disableWhenUpdate) || itemProp.dataField === UIProps.mappingProps;
    let itemPush = (
      <div className={`col-lg-${gridNumField}`} key={'_field_' + itemProp.dataField}>
        <Field
          name={itemProp.dataField}
          component={Input}
          placeholder={itemProp.description}
          label={itemProp.text}
          asteriskSign={asteriskSign}
          readOnly={disableField}
          type={(itemProp.isTypePassword && 'password') || 'text'}
        />
      </div>
    );
    if (itemProp.listData) {
      let dataForList = typeof itemProp.listData === 'string' ? relatedEntities[itemProp.dataField] : itemProp.listData;
      if (itemProp.listDefObject && itemProp.listDefObject.pidProp) {
        let treeDataField = Array.isArray(dataForList) ? treeCustomRelatedBuild(dataForList, itemProp.listDefObject) : dataForList;
        itemPush = (
          <div className={`col-lg-${gridNumField}`} key={'_field_' + itemProp.dataField}>
            {Array.isArray(itemProp.defaultValue) ? (
              <TreeSelectFieldMulti
                name={itemProp.dataField}
                label={itemProp.text}
                asteriskSign={asteriskSign}
                listTree={treeDataField}
                disabled={disableField}
                isMenuOpen={itemProp.isMenuOpen || false}
              />
            ) : (
              <TreeSelectField
                name={itemProp.dataField}
                label={itemProp.text}
                asteriskSign={asteriskSign}
                listTree={treeDataField}
                disabled={disableField}
                isMenuOpen={itemProp.isMenuOpen || false}
              />
            )}
          </div>
        );
      } else {
        let selectDataField =
          typeof itemProp.listData === 'string'
            ? dataForList
            : buildSelectData(dataForList, itemProp.defaultValue, itemProp.listDefObject || undefined);
        let treeDataField = Array.isArray(dataForList) ? nestedNoTree(dataForList, itemProp.listDefObject || undefined) : dataForList;
        let dynamicProps = Array.isArray(itemProp.defaultValue) ? { isMulti: true } : {};
        //console.log(selectDataField);
        itemPush = (
          <div className={`col-lg-${gridNumField}`} key={'_field_' + itemProp.dataField}>
            {!!itemProp.isMenuOpen && Array.isArray(itemProp.defaultValue) ? (
              <TreeSelectFieldMulti
                name={itemProp.dataField}
                label={itemProp.text}
                asteriskSign={asteriskSign}
                listTree={treeDataField}
                disabled={disableField}
                isMenuOpen={true}
              />
            ) : (
              <SelectField
                {...dynamicProps}
                name={itemProp.dataField}
                label={itemProp.text}
                asteriskSign={asteriskSign}
                selectData={selectDataField}
                isDisabled={disableField}
              />
            )}
          </div>
        );
      }
    }
    if (itemProp.typeControl === 'DATEPICKER') {
      itemPush = (
        <div className={`col-lg-${gridNumField}`} key={'_field_' + itemProp.dataField}>
          <DatePickerField name={itemProp.dataField} label={itemProp.text} asteriskSign={asteriskSign} disabled={disableField} />
        </div>
      );
    }
    if (itemProp.typeControl === 'FILE') {
      itemPush = (
        <div className={`col-lg-${gridNumField}`} key={'_field_' + itemProp.dataField}>
          <InputFile name={itemProp.dataField} label={itemProp.text} asteriskSign={asteriskSign} disabled={disableField} placeholder={itemProp.description}/>
      </div>
      );
    }
    if (itemProp.typeControl === 'TEXTAREA') {
      itemPush = (
        <div className="form-group row" key={'_field_' + itemProp.dataField}>
          <div className={`col-lg-12`}>
            <Field
              name={itemProp.dataField}
              component={TextArea}
              placeholder={itemProp.description}
              label={itemProp.text}
              asteriskSign={asteriskSign}
              as="textarea"
              readOnly={disableField}
            />
          </div>
        </div>
      );
    }
    if (itemProp.typeControl === 'TEXTEDITOR') {
      itemPush = (
        <div className="form-group row" key={'_field_' + itemProp.dataField}>
          <div className={`col-lg-12`}>
            <TextAreaEditor name={itemProp.dataField} label={itemProp.text} asteriskSign={asteriskSign} disabled={disableField} placeholder={itemProp.description}/>
          </div>
        </div>
      );
    }
    return itemPush;
  };

  return {
    intl: intl,
    uuidKeyCode: uuidKeyCode,
    isEditForm: isEditForm,
    relatedEntities: relatedEntities,
    buildFieldFromAppModuleConfig: appModuleConfig => {
      let numFieldInRow = appModuleConfig.numFieldInRow;
      let gridNumField = Math.floor(12 / numFieldInRow);
      let objReturnForBuild = {
        validate: {},
        fieldRender: [],
      };
      let legend = 1;
      let lineGroup = 1;
      let itemForBuild = [];
      for (let i = 0; i < appModuleConfig.defObjectProps.length; i++) {
        let itemP = appModuleConfig.defObjectProps[i];

        //trường hợp là một cái legend fieldset
        if (itemP.hasOwnProperty('propsData')) {
          let fieldsetNumFieldInRow = numFieldInRow;
          if (itemP.hasOwnProperty('propsDataNumFieldInRow')) {
            fieldsetNumFieldInRow = itemP.propsDataNumFieldInRow;
          }
          let fieldsetGridNumField = Math.floor(12 / fieldsetNumFieldInRow);
          //push những cái cũ đã có
          itemForBuild.length > 0 &&
            objReturnForBuild.fieldRender.push(
              <div className="form-group row" key={`${uuidKeyCode}_group_${lineGroup}`}>
                {itemForBuild.map((component, key) => (
                  <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
                ))}
              </div>
            );
          itemForBuild = [];
          lineGroup++;
          //begin: xây legend
          let objLegendForBuild = [];
          for (let ji = 0; ji < itemP.propsData.length; ji++) {
            let itemProp = itemP.propsData[ji];

            //bỏ qua với trường đặc biệt là id và pid
            if (itemProp.dataField === 'id' || itemProp.dataField === 'pid') {
              continue;
            }
            let asteriskSign = false;
            //validate
            if (itemProp.validate && itemProp.validate.length > 0) {
              objReturnForBuild.validate[itemProp.dataField] = buildValidateFromAppModuleConfigProps(itemProp);
              asteriskSign = itemProp.validate.some(item => item.type === 'REQUIRE');
            }

            if (itemProp.typeControl === 'TEXTAREA' || itemProp.typeControl === 'TEXTEDITOR') {
              //push những cái cũ đã có
              itemForBuild.length > 0 &&
                objLegendForBuild.push(
                  <div className="form-group row" key={`${uuidKeyCode}_group_${lineGroup}`}>
                    {itemForBuild.map((component, key) => (
                      <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
                    ))}
                  </div>
                );
              itemForBuild = [];
              lineGroup++;
              //push luôn textarea vào
              objLegendForBuild.push(buildField(itemProp, asteriskSign, fieldsetGridNumField));
            } else {
              //textarea
              itemForBuild.push(buildField(itemProp, asteriskSign, fieldsetGridNumField));
              if (
                ji === itemP.propsData.length - 1 || // hết số lượng
                itemForBuild.length === fieldsetNumFieldInRow // bằng với số record trên 1 dòng
              ) {
                itemForBuild.length > 0 &&
                  objLegendForBuild.push(
                    <div className="form-group row" key={`${uuidKeyCode}_group_${lineGroup}`}>
                      {itemForBuild.map((component, key) => (
                        <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
                      ))}
                    </div>
                  );
                //reset
                itemForBuild = [];
                lineGroup++;
              }
            }
          }
          //push legend
          objReturnForBuild.fieldRender.push(
            <fieldset className="border p-2" key={`${uuidKeyCode}_legend_${legend}`}>
              <legend className="w-auto h6">{itemP.text}</legend>
              {objLegendForBuild.map((component, key) => (
                <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
              ))}
            </fieldset>
          );
          legend++;
        } else {
          let itemProp = itemP;
          //bỏ qua với trường đặc biệt là id và pid
          if (itemProp.dataField === 'id' || itemProp.dataField === 'pid') {
            continue;
          }
          let asteriskSign = false;
          //validate
          if (itemProp.validate && itemProp.validate.length > 0) {
            objReturnForBuild.validate[itemProp.dataField] = buildValidateFromAppModuleConfigProps(itemProp);
            asteriskSign = itemProp.validate.some(item => item.type === 'REQUIRE');
          }
          if (itemProp.typeControl === 'TEXTAREA' || itemProp.typeControl === 'TEXTEDITOR') {
            //push những cái cũ đã có
            itemForBuild.length > 0 &&
              objReturnForBuild.fieldRender.push(
                <div className="form-group row" key={`${uuidKeyCode}_group_${lineGroup}`}>
                  {itemForBuild.map((component, key) => (
                    <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
                  ))}
                </div>
              );
            itemForBuild = [];
            lineGroup++;
            objReturnForBuild.fieldRender.push(buildField(itemProp, asteriskSign, gridNumField));
          } else {
            //textarea
            itemForBuild.push(buildField(itemProp, asteriskSign, gridNumField));
            //push những cái row còn lại hoặc khi đủ số dòng trên row
            if (
              i === appModuleConfig.defObjectProps.length - 1 || // hết số lượng
              itemForBuild.length === numFieldInRow // bằng với số record trên 1 dòng
            ) {
              itemForBuild.length > 0 &&
                objReturnForBuild.fieldRender.push(
                  <div className="form-group row" key={`${uuidKeyCode}_group_${lineGroup}`}>
                    {itemForBuild.map((component, key) => (
                      <React.Fragment key={`${uuidKeyCode}_lineItem_${component.key}`}>{component}</React.Fragment>
                    ))}
                  </div>
                );
              //reset
              itemForBuild = [];
              lineGroup++;
            }
          }
        }
      }
      return objReturnForBuild;
    },
  };
}
