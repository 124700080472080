/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { Badge } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Button } from '@material-ui/core';
import axios from 'axios';
import * as actions from '../../../../../app/modules/AppBaseCRUD/_redux/list/listActions';

export function UserNotificationsDropdown() {
  const dispatch = useDispatch();
  const listMessage = useSelector(state => state.baseCRUDList.messageNotification);
  const uiService = useHtmlClassService();
  const numberMessage = listMessage.filter(item => item.daXem === 0).length;
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.notifications.layout') === 'offcanvas',
    };
  }, [uiService]);
  const setMessage = values => {
    const data = [];
    listMessage.map((item) => {
      data.push(item.id);
    })
    axios.put(`/services/qtht/api/notification/message`, data).then((res) => {
      if (res.status == 200) {
        axios.post(`/services/qtht/api/notification/message/20`).then((res) => {
          if (res.status == 200) {
            dispatch(actions.reloadMessageNotification(res.data.result.entities))
          }
        });
      }
    });
  };
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="user-notification-tooltip">Thông báo</Tooltip>}>
              <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Code/bell.svg')} />
                  {numberMessage !== 0 && (
                    <div className="message-chuaxem">
                      <Badge badgeContent={numberMessage} max={999} />
                    </div>
                  )}
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}

              <div className="title-thongbao-socket">
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-thongbao">Thông báo</span>
                  <div className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Code/bell.svg')} />
                    <div className="message-chuaxem-thongbao">{numberMessage}</div>
                  </div>
                  <Button variant="contained" className="button-xoa-thongbao" onClick={setMessage}>Xóa</Button>
                </h4>

              </div>
              <div className="notification">
                <div className="tab-message">
                  {listMessage.length > 0 && listMessage.map((e, i) => {
                    var res = e.content.replace(/'/g, '"') + "";
                    var text = JSON.parse(res);
                    if (e.daXem == 0) {
                      return (
                        <div className="tab-content-notification" key={`notif_${i}`}>
                          <div>
                            <div className="d-flex flex-column font-weight-bold">

                              <div className="tab-content-message">
                                <div className="icon-message">
                                  {text.type === "CHUYEN" ? <ArrowForwardIcon /> : text.type === "TRA" ? <ArrowBackIcon /> : <NotificationsActiveIcon />}
                                </div>
                                <span className="text-nguoigui">{text.nguoigui}</span>
                                <div>
                                  <span className="text-tilte">{e.title}</span>
                                  <span className="text-tenworkspace">{text.tenbieumau}</span>
                                </div>
                                <div>
                                  <span className="text-tilte" >đến nhóm </span>
                                  <span class="text-nhomnhan">{text.nhomnhan}</span>
                                </div>
                              </div>
                              {/* {e.daXem === 0 && <span className="pick-checkdaxem"> </span>} */}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
