import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data._name}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const animatedComponents = makeAnimated();

const colourStyles = {
  multiValue: (styles, { data }) => {
    const color = (data.color && chroma(data.color)) || undefined;
    if (color !== undefined) {
      return data.isFixed ? { ...styles, backgroundColor: 'gray' } : { ...styles, backgroundColor: color.alpha(0.1).css() };
    }
    return data.isFixed ? { ...styles, backgroundColor: 'gray' } : { ...styles };
  },
  multiValueLabel: (styles, { data }) => {
    return data.isFixed ? { ...styles, fontWeight: 'bold', color: 'white', paddingRight: 6 } : { ...styles, color: data.color };
  },
  multiValueRemove: (styles, { data }) => {
    return data.isFixed
      ? { ...styles, display: 'none' }
      : {
          ...styles,
          color: data.color,
          ':hover': {
            backgroundColor: data.color,
            color: 'white',
          },
        };
  },
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = (data.color && chroma(data.color)) || undefined;
    if (color !== undefined) {
      return {
        ...styles,
        backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
        color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    }
    return { ...styles };
  },
  singleValue: (styles, { data }) => (data.color && { ...styles, ...dot(data.color) }) || { ...styles },
};

const orderOptions = values => {
  return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

export function SelectField({ label, asteriskSign, isMulti, selectData, ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control', 'form-control-solid', 'crnx-select'];
    if (isMulti && isMulti === true) {
      classes.push('basic-multi-select');
    }

    if (touched && errors) {
      classes.push('is-invalid-select');
    }

    if (touched && !errors) {
      classes.push('is-valid-select');
    }

    return classes.join(' ');
  };

  const onChange = (value, { action, removedValue }) => {
    if (isMulti && isMulti === true) {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'remove-value':
        case 'pop-value':
          if (removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          value = selectData && selectData.entities.filter(v => v.isFixed);
          break;
      }
      value = orderOptions(value);
    } else {
      value = value && ((value._cid && value._cid) || value);
    }
    setFieldValue(field.name, value);
  };

  const checkValueInttoObject = choose => {
    if (Array.isArray(choose)) {
      let out = [];
      for (var node of choose) {
        if (typeof node != 'object') {
          selectData && selectData.entiObj && out.push(selectData.entiObj[node + '']);
        } else {
          out.push(node);
        }
      }
      return out;
    } else {
      if (typeof choose != 'object') {
        return selectData && selectData.entiObj && selectData.entiObj[choose + ''];
      }
      return choose;
    }
  };

  const dynamicSelectProps = isMulti && isMulti === true ? { isMulti } : {};
  // console.log('Selected:', selectData, isMulti, field.name, field.value);
  return (
    <>
      {label && (
        <label>
          {label} {asteriskSign && <i style={{ color: 'red' }}>*</i>}
        </label>
      )}
      <Select
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
        {...dynamicSelectProps}
        value={(field.value && checkValueInttoObject(field.value)) || undefined}
        styles={colourStyles}
        classNamePrefix="crnx-select"
        onChange={onChange}
        closeMenuOnSelect={false}
        components={animatedComponents}
        formatGroupLabel={formatGroupLabel}
        getOptionLabel={opt => opt._name}
        getOptionValue={opt => opt._cid}
        isClearable={(Array.isArray(field.value) && field.value.some(v => !v.isFixed)) || true}
        options={(selectData && selectData.entities && selectData.entities) || []}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
      ) : null}
      {/* <div className="feedback">
        Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
      </div> */}
    </>
  );
}
