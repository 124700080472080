import { flatten, uniq, isEmpty } from 'lodash';

export const getValla = (arrItemDef, name) => {
  let indentityName = '';
  if (Array.isArray(name)) {
    indentityName = name.reduce((str, it) => (str += arrItemDef[it] + ' '), '');
  } else {
    indentityName = arrItemDef[name];
  }
  return indentityName;
};

export const getVallaOther = (arrItemDef, name, nameOther) => {
  let indentityName = '';
  if (Array.isArray(name)) {
    indentityName = name.reduce((str, it) => (str += arrItemDef[it] + ' '), '');
    indentityName+=(nameOther && `(${arrItemDef[nameOther]})`)||'';
  } else {
    indentityName = arrItemDef[name];
    indentityName+=(nameOther && `(${arrItemDef[nameOther]})`)||'';
  }
  return indentityName;
};

export const treeCustomRelated = (entities, mapParent = { cidProp: 'id', pidProp: 'pid', nameProp: 'ten', codeProp: 'ma' }) => {
  const idCode = (mapParent.codeProp && mapParent.codeProp) || mapParent.cidProp;

  const findParents = (node, searchForName) => {
    if (node[mapParent.cidProp] === searchForName) {
      return [];
    }
    if (Array.isArray(node.children)) {
      const parentNode = node;
      for (var treeNode of node.children) {
        const childResult = findParents(treeNode, searchForName);
        if (Array.isArray(childResult)) {
          if (treeNode[mapParent.pidProp] && treeNode[mapParent.pidProp] !== '' && treeNode[mapParent.pidProp] !== '0') {
            return [parentNode[idCode]].concat(childResult);
          } else {
            return childResult;
          }
        }
      }
    }
  };

  /*
  //slowed
  const getNestedChildren = (arr, parent) => {
    let out = { treeData: [], entiObj: {} };
    for (let i in arr) {
      let itemReturn = { ...arr[i] };
      let itemFlat = { ...arr[i], children: [] };

      itemReturn[mapParent.cidProp] = arr[i][mapParent.cidProp] + '';
      itemReturn[mapParent.pidProp] = arr[i][mapParent.pidProp] + '';
      itemFlat[mapParent.cidProp] = arr[i][mapParent.cidProp] + '';
      itemFlat[mapParent.pidProp] = arr[i][mapParent.pidProp] + '';
      let idName = getValla(arr[i], mapParent.nameProp);
      let idNameOther = getVallaOther(arr[i], mapParent.nameProp,mapParent.otherName);
      if (arr[i][mapParent.pidProp] + '' === parent + '') {
        let children = getNestedChildren(arr, arr[i][mapParent.cidProp] + '');
        if (children.treeData.length > 0) {
          itemReturn.children = children.treeData;
          itemFlat.children = children.treeData.reduce((arrChild, itemChild) => {
            arrChild.push(itemChild[idCode] + '');
            return arrChild;
          }, []);
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode] + '', // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode] + '',
            _name: idNameOther, // dùng cho tree select
          };
          out.entiObj = { ...out.entiObj, ...children.entiObj };
        } else {
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode] + '', // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode] + '',
            _name: idNameOther, // dùng cho tree select
          };
        }
        out.treeData.push({
          ...itemReturn,
          value: arr[i][idCode] + '', // dùng cho tree select
          title: idNameOther, // dùng cho tree select
          label: idNameOther, // dùng cho tree select
          _cid: arr[i][idCode] + '',
          _name: idNameOther, // dùng cho tree select
          expanded: false, // dùng cho tree sortable cho dinh nghia mau
        });
      }
    }
    return out;
  };*/

  const getNestedChildren = (list, parent) => {
    var map = {},
      node,
      roots = [],
      rootsObj = {},
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i][mapParent.cidProp]] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].childrenObj = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      let idName = getValla(list[i], mapParent.nameProp);
      let idNameOther = getVallaOther(list[i], mapParent.nameProp,mapParent.otherName);
      node = {
        ...list[i],
        value: list[i][idCode] + '', // dùng cho tree select
        title: idNameOther, // dùng cho tree select
        label: idNameOther, // dùng cho tree select
        _cid: list[i][idCode] + '',
        _name: idNameOther, // dùng cho tree select
        expanded: false, // dùng cho tree sortable cho dinh nghia mau
      };
      node[mapParent.cidProp] = list[i][mapParent.cidProp] + '';
      node[mapParent.pidProp] = list[i][mapParent.pidProp] + '';

      if (node[mapParent.pidProp] + '' !== parent + '') {
        // if you have dangling branches check that map[node[mapParent.pidProp]]] exists
        if (list[map[node[mapParent.pidProp]]] && list[map[node[mapParent.pidProp]]].children) {
          list[map[node[mapParent.pidProp]]].children.push(node);
        } else {
          let obj = { ...list[map[node[mapParent.pidProp]]], children: [node] };
          list[map[node[mapParent.pidProp]]] = obj;
        }

        list[map[node[mapParent.pidProp]]] &&
          list[map[node[mapParent.pidProp]]].childrenObj &&
          list[map[node[mapParent.pidProp]]].childrenObj.push(node[idCode]);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      } else {
        roots.push(node);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      }
    }
    return { treeData: roots, entiObj: rootsObj };
  };

  const entitiesRaw = [...entities];
  const treeBuild = entitiesRaw
    ? getNestedChildren(entitiesRaw, '0')
    : {
        treeData: [],
        entiObj: {},
      };

  //bắt buộc dùng string nhé để không lỗi
  const entitiesTreeDefault = {
    entities: [],
    ...treeBuild,
    treeRowKeys: {},
  };

  const entitiesTree = entities
    ? entities.reduce((arr, item) => {
        let idName = getValla(item, mapParent.nameProp);
        let idNameOther = getVallaOther(item, mapParent.nameProp,mapParent.otherName);
        let itemReturn = {
          ...item,
          value: item[idCode] + '',
          title: idNameOther,
          label: idNameOther,
          _cid: item[idCode] + '',
          _name: idNameOther,
        };
        itemReturn[mapParent.cidProp] = item[mapParent.cidProp] + '';
        itemReturn[mapParent.pidProp] = item[mapParent.pidProp] + '';
        arr.entities.push(itemReturn);
        arr.treeRowKeys[item[idCode]] = findParents({ children: arr.treeData }, item[mapParent.cidProp] + '');
        return arr;
      }, entitiesTreeDefault)
    : entitiesTreeDefault;

  return {
    ...entitiesTree,
    valueKey: idCode,
    config: mapParent,
  };
};

export const treeCustomDinhNghiaRelated = (entities, mapParent = { cidProp: 'id', pidProp: 'pid', nameProp: 'ten', codeProp: 'ma' }) => {
  const idCode = (mapParent.codeProp && mapParent.codeProp) || mapParent.cidProp;

  const findParents = (node, searchForName) => {
    if (node[mapParent.cidProp] === searchForName) {
      return [];
    }
    if (Array.isArray(node.children)) {
      const parentNode = node;
      for (var treeNode of node.children) {
        const childResult = findParents(treeNode, searchForName);
        if (Array.isArray(childResult)) {
          if (treeNode[mapParent.pidProp] && treeNode[mapParent.pidProp] !== '' && treeNode[mapParent.pidProp] !== '0') {
            return [parentNode[idCode]].concat(childResult);
          } else {
            return childResult;
          }
        }
      }
    }
  };

  /*
  //slowed
  const getNestedChildren = (arr, parent) => {
    let out = { treeData: [], entiObj: {} };
    for (let i in arr) {
      let itemReturn = { ...arr[i] };
      let itemFlat = { ...arr[i], children: [] };

      itemReturn[mapParent.cidProp] = arr[i][mapParent.cidProp] + '';
      itemReturn[mapParent.pidProp] = arr[i][mapParent.pidProp] + '';
      itemFlat[mapParent.cidProp] = arr[i][mapParent.cidProp] + '';
      itemFlat[mapParent.pidProp] = arr[i][mapParent.pidProp] + '';
      let idName = getValla(arr[i], mapParent.nameProp);
      let idNameOther = getVallaOther(arr[i], mapParent.nameProp,mapParent.otherName);
      if (arr[i][mapParent.pidProp] + '' === parent + '') {
        let children = getNestedChildren(arr, arr[i][mapParent.cidProp] + '');
        if (children.treeData.length > 0) {
          itemReturn.children = children.treeData;
          itemFlat.children = children.treeData.reduce((arrChild, itemChild) => {
            arrChild.push(itemChild[idCode] + '');
            return arrChild;
          }, []);
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode] + '', // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode] + '',
            _name: idNameOther, // dùng cho tree select
          };
          out.entiObj = { ...out.entiObj, ...children.entiObj };
        } else {
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode] + '', // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode] + '',
            _name: idNameOther, // dùng cho tree select
          };
        }
        out.treeData.push({
          ...itemReturn,
          value: arr[i][idCode] + '', // dùng cho tree select
          title: idNameOther, // dùng cho tree select
          label: idNameOther, // dùng cho tree select
          _cid: arr[i][idCode] + '',
          _name: idNameOther, // dùng cho tree select
          expanded: false, // dùng cho tree sortable cho dinh nghia mau
        });
      }
    }
    return out;
  };*/

  const getNestedChildren = (list, parent) => {
    var map = {},
      node,
      roots = [],
      rootsObj = {},
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i][mapParent.cidProp]] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].childrenObj = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      let idName = getValla(list[i], mapParent.nameProp);
      let idNameOther = getVallaOther(list[i], mapParent.nameProp,mapParent.otherName);
      node = {
        ...list[i],
        value: list[i][idCode] + '', // dùng cho tree select
        title: idNameOther, // dùng cho tree select
        label: idNameOther, // dùng cho tree select
        _cid: list[i][idCode] + '',
        _name: idNameOther, // dùng cho tree select
        expanded: false, // dùng cho tree sortable cho dinh nghia mau
      };
      let keyArray = Object.keys(list[i]);
      for(var mi = 0;mi<keyArray.length;mi++){
        // console.log(typeof list[i][keyArray[mi]] ==="string" && list[i][keyArray[mi]].split(',').length >1)
        if(typeof list[i][keyArray[mi]] ==="string" && list[i][keyArray[mi]].split(',').length >1){
          node[keyArray[mi]] = list[i][keyArray[mi]].split(',')
        }
      }
      node[mapParent.cidProp] = list[i][mapParent.cidProp] + '';
      node[mapParent.pidProp] = list[i][mapParent.pidProp] + '';

      if (node[mapParent.pidProp] + '' !== parent + '') {
        // if you have dangling branches check that map[node[mapParent.pidProp]]] exists
        if (list[map[node[mapParent.pidProp]]] && list[map[node[mapParent.pidProp]]].children) {
          list[map[node[mapParent.pidProp]]].children.push(node);
        } else {
          let obj = { ...list[map[node[mapParent.pidProp]]], children: [node] };
          list[map[node[mapParent.pidProp]]] = obj;
        }

        list[map[node[mapParent.pidProp]]] &&
          list[map[node[mapParent.pidProp]]].childrenObj &&
          list[map[node[mapParent.pidProp]]].childrenObj.push(node[idCode]);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      } else {
        roots.push(node);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      }
    }
    return { treeData: roots, entiObj: rootsObj };
  };

  const entitiesRaw = [...entities];
  const treeBuild = entitiesRaw
    ? getNestedChildren(entitiesRaw, '0')
    : {
        treeData: [],
        entiObj: {},
      };

  //bắt buộc dùng string nhé để không lỗi
  const entitiesTreeDefault = {
    entities: [],
    ...treeBuild,
    treeRowKeys: {},
  };

  const entitiesTree = entities
    ? entities.reduce((arr, item) => {
        let idName = getValla(item, mapParent.nameProp);
        let idNameOther = getVallaOther(item, mapParent.nameProp,mapParent.otherName);
        let itemReturn = {
          ...item,
          value: item[idCode] + '',
          title: idNameOther,
          label: idNameOther,
          _cid: item[idCode] + '',
          _name: idNameOther,
        };
        itemReturn[mapParent.cidProp] = item[mapParent.cidProp] + '';
        itemReturn[mapParent.pidProp] = item[mapParent.pidProp] + '';
        arr.entities.push(itemReturn);
        arr.treeRowKeys[item[idCode]] = findParents({ children: arr.treeData }, item[mapParent.cidProp] + '');
        return arr;
      }, entitiesTreeDefault)
    : entitiesTreeDefault;

  return {
    ...entitiesTree,
    valueKey: idCode,
    config: mapParent,
  };
};

export const nestedNoTree = (entities, mapParent = { cidProp: 'cid', nameProp: 'name' }, useChildren = true) => {
  let indentity = (mapParent && mapParent.codeProp && mapParent.codeProp) || (mapParent && mapParent.cidProp) || 'cid';
  let indentityName = (mapParent && mapParent.nameProp) || 'name';

  const entitiesTreeDefault = {
    entities: [],
    entiObj: {},
    treeRowKeys: {},
  };

  const entitiesTree =
    (entities &&
      entities.reduce((arr, item) => {
        let idName = getValla(item, indentityName);
        let idNameOther = getVallaOther(item, indentityName, undefined);
        let itemReturn = {
          ...item,
          value: item[indentity] + '',
          title: idNameOther,
          label: idNameOther,
          _cid: item[indentity] + '',
          _name: idNameOther,
        };
        itemReturn[indentity] = item[indentity] + '';
        arr.entities.push(itemReturn);
        //đoạn này fix có vì lí do cây inteligent có sử dụng nhưng cây tree định nghĩa ko có
        if (useChildren) {
          itemReturn.children = [];
        }
        arr.entiObj[item[indentity]] = itemReturn;
        arr.treeRowKeys[item[indentity]] = [];
        return arr;
      }, entitiesTreeDefault)) ||
    entitiesTreeDefault;

  return {
    ...entitiesTree,
    treeData: entitiesTree.entities,
    valueKey: indentity,
    config: mapParent,
  };
};

export const treeCustomRelatedBuild = (entities, mapParent, useChildren = true) => {
  return (
    (mapParent !== null && mapParent.pidProp && treeCustomRelated(entities, mapParent)) || nestedNoTree(entities, mapParent, useChildren)
  );
};
export const treeCustomDinhNghiaRelatedBuild = (entities, mapParent, useChildren = true) => {
  return (
    (mapParent !== null && mapParent.pidProp && treeCustomDinhNghiaRelated(entities, mapParent)) || nestedNoTree(entities, mapParent, useChildren)
  );
};

export const getOtherNested = tree => {
  return {
    entitiesTree: tree,
    rowKeys: () => Object.keys(this.entitiesTree.treeRowKeys),
    expandableRowKeys: () => uniq(flatten(Object.values(this.entitiesTree.treeRowKeys)), true),
    nonExpandable: () => this.rowKeys().filter(x => !this.expandableRowKeys().includes(x)),
    hiddenRowKeys: () => this.rowKeys().filter(x => !isEmpty(this.entitiesTree.treeRowKeys[x])),
    treeRowChild: () =>
      this.rowKeys().reduce((obj, item) => {
        obj[item] = this.rowKeys().reduce((arr, itx) => {
          if (this.entitiesTree.treeRowKeys[itx] && this.entitiesTree.treeRowKeys[itx].includes(item)) {
            arr.push(itx);
          }
          return arr;
        }, []);
        return obj;
      }, {}),
  };
};
