import { SERVER_API_URL } from '../config/constants';

export default function setupAxios(axios, store, onUnauthenticated) {
  const TIMEOUT = 1 * 60 * 1000;
  axios.defaults.timeout = TIMEOUT;
  axios.defaults.baseURL = SERVER_API_URL;
  // axios.defaults.headers.common['Token'] = localStorage.getItem("token");
  // axios.defaults.headers.common['Device'] = "device";

  const onRequestSuccess = config => {
    const {
      auth: { authToken },
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
      if (config.data !== undefined) {
        let itemProps = Object.keys(config.data);
        let keyFile = itemProps.reduce((obj, item) => {
          let datai = config.data[item];
          if (datai instanceof File || datai instanceof Blob) {
            obj = true;
          }
          return obj;
        }, false);
        if (keyFile) {
          config.headers['Content-Type'] = 'multipart/form-data';
          var form_data = new FormData();
          for (var key in config.data) {
            form_data.append(key, config.data[key]);
          }
          config.data = form_data;
        }
      }
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    //return new Promise((resolve, reject) => {
    const status = err.status || (err.response ? err.response.status : 0);
    // const originalReq = err.config;
    if (status === 403 || status === 401 || status === 504) {
      //if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      // originalReq._retry = true;
      // let res = fetch('/auth/refresh', {
      //     method: 'POST',
      //     mode: 'cors',
      //     cache: 'no-cache',
      //     credentials: 'same-origin',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Device': 'device',
      //         'Token': localStorage.getItem("token")
      //     },
      //     redirect: 'follow',
      //     referrer: 'no-referrer',
      //     body: JSON.stringify({
      //         token: localStorage.getItem("token"),
      //         refresh_token: localStorage.getItem("refresh_token")
      //     }),
      // }).then(res => res.json()).then(res => {
      //     console.log(res);
      //     this.setSession({token: res.token, refresh_token: res.refresh});
      //     originalReq.headers['Token'] = res.token;
      //     originalReq.headers['Device'] = "device";
      //     return axios(originalReq);
      // });
      // resolve(res);
      onUnauthenticated();
    }
    return Promise.reject(err);
    //});
  };

  axios.interceptors.request.use(onRequestSuccess, err => Promise.reject(err));
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
}
