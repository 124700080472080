import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call } from "redux-saga/effects";
import { getUserByToken, logout, fetchListRelatedApp } from "./authCrud";

const today = new Date();
export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  LogoutRequested: "[Request Logout] Action",
  UserLoaded: "[Load User] Auth API",
  FetchRelatedEntities: "[Load Related Entities] API",
  setPickMonthYear: "[setPickMonthYear] Action",
};

const initialAuthState = {
  namTaiChinh: { year: today.getFullYear(), month: today.getMonth() + 1 },
  user: undefined,
  authToken: undefined,
  token: undefined,
  relatedEntities:{}
};

export const reducer = persistReducer(
  { storage, key: "crnx-auth-14@!f54*(errr5e!&6r1erfa9s4$dee23", whitelist: ["user", "authToken", "token", "namTaiChinh"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { dataAuthToken } = action.payload;
        return {
          authToken: dataAuthToken.access_token,
          token:dataAuthToken,
          user: undefined,
          relatedEntities:{}
        };
      }

      case actionTypes.setPickMonthYear: {
        const { namTaiChinh } = action.payload;
        return {
          ...state,
          namTaiChinh: namTaiChinh
        };
      }

      case actionTypes.Register: {
        const { dataAuthToken } = action.payload;
        return {
          authToken: dataAuthToken.access_token,
          token:dataAuthToken,
          user: undefined,
          relatedEntities:{}
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        const userDetail = {...user, fullname: user.firstName}
        //for mock
        //const userDetail = {...user}
        return { ...state, user: userDetail };
      }

      case actionTypes.FetchRelatedEntities: {
        const { relatedEnApp } = action.payload;
        const relatedEntities = {...state.relatedEntities, ...relatedEnApp}
        return { ...state, relatedEntities: relatedEntities };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: dataAuthToken => ({ type: actionTypes.Login, payload: { dataAuthToken } }),
  register: dataAuthToken => ({
    type: actionTypes.Register,
    payload: { dataAuthToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  logoutUser: () => ({ type: actionTypes.LogoutRequested }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fetchRelatedEntities: (relatedEnApp) => ({ type: actionTypes.FetchRelatedEntities, payload: { relatedEnApp } }),
  setPickMonthYear: (namTaiChinh) => ({ type: actionTypes.setPickMonthYear, payload: { namTaiChinh } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
    yield put(actions.setPickMonthYear({ year: today.getFullYear(), month: today.getMonth() + 1 }));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  //sagas
  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserLoaded, function* userLoaded() {
    //Load trước 12 danh mục vào redux để khi cần thì dùng
    const relatedEnApp =  yield call(fetchListRelatedApp);
    yield put(actions.fetchRelatedEntities(relatedEnApp));
  });

  yield takeLatest(actionTypes.LogoutRequested, function* logoutRequested() {
    yield logout();
    yield put(actions.logout());
  });
}
