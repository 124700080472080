export const buildSelectData = (data,defaultVal,def) =>{
  let returnData = {
    entities:[],
    entiObj:{}
  }
  return (Array.isArray(data) && data.reduce((obj, item) =>{
    let dataChild = [];
    let indentity = (def && item[def.cidProp]) || item.cid;
    let indentityName = item.name;
    if(def){
      if(Array.isArray(def.nameProp)){
        indentityName = def.nameProp.reduce((str,it)=>str+=item[it]+" ","")
      }else{
        indentityName = item[def.nameProp];
      }
    }
    let itemReturn = {
      ...item,
      cid:indentity,
      _cid: indentity,
      _name: indentityName,
      isFixed: Array.isArray(defaultVal) ? defaultVal.some(ele=>ele+"" === indentity+""): false
    }
    if(item.options && Array.isArray(item.options)){
      dataChild = buildSelectData (item.options,defaultVal);
      itemReturn["options"] = dataChild.entities;
    }
    obj.entities.push(itemReturn);
    obj.entiObj[indentity+""] = itemReturn;
    obj.entiObj = {...obj.entiObj,...dataChild.entiObj}
    return obj
  },returnData)) || returnData;
}
