/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
/* eslint-disable no-new-func */
//Styles
import { isEmpty } from 'lodash';
const boldCenter = { fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' };
const boldLeft = { fontWeight: 'bold', textAlign: 'left', verticalAlign: 'middle' };
export const buildNameWithouthSpace = name => name.replace(/\s/g, '+');
export const buildSheets = (sheets, bieumauDta, action, dataBieuMau, activeCell = 'A1', daluukho = 0, debug = false, conflict = []) => {
  // const dataBieuMau = JSON.parse(JSON.stringify(dataBM));
  //console.log("dataBieuMau",dataBieuMau)
  const actionArr = action.split(',');
  var congthucChangeData = {};
  const newSheets = bieumauDta.reduce((arr, item, key) => {
    //console.log("dataBieuMauiD",item.id)
    const rowData = (dataBieuMau[item.id] && buildRows(congthucChangeData, dataBieuMau[item.id], debug, item.id)) || [];
    //console.log('buildRows', rowData);
    if (rowData.length > 0) {
      let index = (rowData.length - 1 > 0 && rowData.length - 1) || 0;
      arr.push({
        name: `${key}. ${item.ten}`,
        key: `bieumau_${item.id}`,
        keyDefCode: item.ma,
        keyDefId: item.id,
        usedRange: {},
        maxRows: rowData.length,
        isProtected: actionArr.indexOf('CHUYEN') !== -1 && daluukho != 1 ? false : true,
        protectSettings: {
          selectCells: true,
          formatCells: false,
          formatRows: false,
          formatColumns: false,
          insertLink: false,
        },
        conditionalFormats: [
          {
            type: 'EqualTo',
            cFColor: 'RedFT',
            value: conflict,
            range: 'A1:' + getAlphalabel((rowData[index] && rowData[index].cells.length) || 1) + rowData.length,
          },
        ],
        activeCell: activeCell,
        selectedRange: activeCell,
        rows: rowData,
      });
    } else {
      arr.push({
        name: `${key}. ${item.ten}`,
        key: `bieumau_${item.id}`,
        keyDefCode: item.ma,
        keyDefId: item.id,
        usedRange: {},
        maxRows: rowData.length,
        isProtected: actionArr.indexOf('CHUYEN') !== -1 && daluukho != 1 ? false : true,
        protectSettings: {
          selectCells: true,
          formatCells: false,
          formatRows: false,
          formatColumns: false,
          insertLink: false,
        },
        activeCell: activeCell,
        selectedRange: activeCell,
        rows: rowData,
      });
    }
    return arr;
  }, []);
  //console.log('congthucChangeData', buildWithOldSheets(sheets, newSheets, actionArr), sheets, newSheets, actionArr);
  return {
    dataSheet: buildWithOldSheets(sheets, newSheets, actionArr),
    congthucChange: congthucChangeData,
  };
};

export const getAlphalabel = col => {
  var cols = [];
  var n = 0;
  var charText = 'A';
  while (col > 0 && n < 9) {
    col--;
    var aCharNo = charText.charCodeAt(0);
    cols[n] = String.fromCharCode((col % 26) + aCharNo);
    col = parseInt((col / 26).toString(), 10);
    n++;
  }
  var chs = [];
  for (var i = 0; i < n; i++) {
    chs[n - i - 1] = cols[i];
  }
  return chs.join('');
};

const buildWithOldSheets = (oldSheets, newSheets, actionArr) => {
  // let comparer = (otherArray) =>{
  //   return (current)=>{
  //     return otherArray.filter(function(other){
  //       return other.keyDefId == current.keyDefId
  //     }).length == 0;
  //   }
  // }
  // let onlyInOldSheets = oldSheets.filter(comparer(newSheets));
  // let onlyInNewSheets = newSheets.filter(comparer(oldSheets));
  // let diffSheets = onlyInOldSheets.concat(onlyInNewSheets);
  return newSheets.reduce((arr, itemNew) => {
    let arrLoop = oldSheets.reduce((arrOld, itemOld) => {
      if (itemNew.keyDefId == itemOld.keyDefId) {
        let rowMerge = itemOld.rows;
        //kiểm tra lại vì khi thu hồi thì không chuyển dc
        // if (actionArr.indexOf('CHUYEN') !== -1) {
        rowMerge = buildRowMerge(itemOld.rows, itemNew.rows);
        console.log('rowMerge', rowMerge, itemOld.rows, itemNew.rows);
        // }
        arrOld.push({
          ...itemNew,
          rows: rowMerge,
          activeCell: (itemOld.selectedRange && itemOld.selectedRange) || itemNew.activeCell,
          selectedRange: (itemOld.selectedRange && itemOld.selectedRange) || 'A1:A1',
          topLeftCell: (itemOld.topLeftCell && itemOld.topLeftCell) || 'A1',
        });
      }
      return arrOld;
    }, []);
    if (arrLoop.length > 0) {
      arr = [...arr, ...arrLoop];
    } else {
      arr.push(itemNew);
    }
    return arr;
  }, []);
};

const getValueCellWithOldData = (cellOld, cellNew) => {
  // if(cellOld.key=="gridrow_12_gridcell_2"){
  //   console.log('getValueCellWithOldData0',cellOld.value,cellNew.value)
  // }
  let value = cellNew.value;
  if (cellNew.type && cellNew.type == 'KEY' && cellNew.is && cellNew.is == 'CELL') {
    let dataSplit = cellNew.keyDef.split('_');
    let isChuThich = dataSplit[12] * 1;
    if (isChuThich == 1) {
      if (cellOld && cellOld.value) {
        if (cellOld.value != cellNew.value) {
          //giá trị cũ = rỗng
          if (['', null, 0, '0', 'undefined', 'null'].indexOf(cellOld.value + '') !== -1) {
            value = cellNew.value + '';
            console.log('getValueCellWithOldData1', cellOld.value, isChuThich, cellNew.value, value);
          } else {
            //có giá trị cũ
            //giá trị mới = rỗng
            if (['', null, 0, '0', 'undefined', 'null'].indexOf(cellNew.value + '') !== -1) {
              value = cellOld.value + '';
              console.log('getValueCellWithOldData2', cellOld.value, isChuThich, cellNew.value, value);
            }
          }
        }
      }
    }
  } else if (cellNew.type && cellNew.type == 'TEXT' && cellNew.is && ['ROW', 'COLTD', 'COLSS', 'COLDX'].indexOf(cellNew.is) != -1) {
    // }else if((cellNew.type &&  cellNew.type == 'TEXT') && (cellNew.is &&  cellNew.is == 'COL')){
  } else {
    if (cellOld && [null, 'null', '', undefined, 'undefined'].indexOf(cellOld.value + '') === -1 && !isEmpty(cellOld.value)) {
      value = cellOld.value + '';
    }
  }
  return {
    ...cellNew,
    style: (cellOld && cellOld.style && cellOld.style) || cellNew.style,
    formula: (cellOld && cellOld.formula && cellOld.formula) || cellNew.formula,
    value: value,
  };
};

const buildRowMerge = (oldSheets, newSheets) => {
  var i, j;
  var data = [];
  for (i = 0; i < newSheets.length; i++) {
    var row = newSheets[i];
    var cellsData = [];
    for (j = 0; j < row.cells.length; j++) {
      var cell = newSheets[i].cells[j];
      if (oldSheets[i] && oldSheets[i].cells) {
        var listCell = removeCellMergeColRowSpan(oldSheets[i].cells);
        var c = listCell[j];
        var b = !!listCell[j];
        if (i == 12 && j == 2) {
          console.log('getValueCellWithOldDataE', i, j);
        }
        var a = getValueCellWithOldData(c, cell);
        cell = b ? a : cell;
      }
      cellsData.push(cell);
    }
    data.push({ ...row, cells: cellsData });
  }
  return data;
};

export const removeCellMergeColRowSpan = arrCells => {
  return arrCells.reduce((arr, item) => {
    if (item && item.colSpan > 0) {
      arr.push(item);
    }
    return arr;
  }, []);
};

// const getValueCell = (cell, data) => {
//   if (cell.is === 'CELL' && cell.type === 'KEY') {
//     if (cell.keyPhanRa.length > 0) {
//       return cell.keyPhanRa.reduce((count, item) => {
//         if (data[item]) {
//           count += data[item] * 1;
//         }
//         return count;
//       }, 0);
//     } else {
//       return (data[cell.key] && data[cell.key] * 1) || 0;
//     }
//   }
//   return cell.key;
// };

const getValueCellWithFormat = (idBieuMau, congthucChangeData, cell, data, datacongthuccot, datacongthucdong) => {
  if (cell.is === 'CELL' && cell.type === 'KEY') {
    let dataSplit = cell.key.split('_');
    let isChuThich = dataSplit[12] * 1;
    let congthuc;
    if (cell.keycongthucdong) {
      congthuc = datacongthucdong[cell.key];
    }
    if (cell.keycongthuccot) {
      congthuc = datacongthuccot[cell.key];
    }
    //console.log("congthuc", cell.key, congthuc)
    if (isChuThich) {
      if (congthuc) {
        let valueFormulaCell = congthuc.replaceAll('[', '').replaceAll(']', '');
        let valueCT = getValueF(cell, valueFormulaCell, data);
        congthucChangeData[cell.key] = { ...cell, valueCT: valueCT.cthuc, idBieuMau: idBieuMau };
        // console.log("cellChuThichCongThuc",cell)
        //console.log(congthuc,valueCT)
        return valueCT.cthuc;
      } else {
        if (data[cell.key]) {
          if (['', null, 0, '0', 'undefined', 'null'].indexOf(data[cell.key] + '') == -1) {
            return (data[cell.key] || (cell.value && cell.value)) + '';
          } else {
            return (cell.value && cell.value) + '';
          }
        } else {
          return '';
        }
      }
    } else {
      if (congthuc) {
        let valueFormulaCell = congthuc.replaceAll('[', '').replaceAll(']', '');
        let valueCT = getValueF(cell, valueFormulaCell, data);
        // congthucChangeData[cell.key] = {...cell,valueCT: valueCT.cthuc}
        // console.log("cellCongThuc",cell)
        //console.log(congthuc,valueCT)
        return valueCT.cthuc;
      } else {
        if (cell.keyPhanRa.length > 0) {
          return cell.keyPhanRa.reduce((count, item) => {
            if (data[item]) {
              count += data[item] * 1;
            }
            return count;
          }, 0);
        } else {
          return (data[cell.key] && data[cell.key] * 1) || 0;
        }
      }
    }
  }
  return cell.key;
};

const getValueF = (cell, congthuc, data) => {
  let listData = Object.entries(data);
  let congthucAfterPase = congthuc;
  listData.forEach(([key, value]) => {
    if (congthuc.toString().includes(key)) {
      congthucAfterPase = congthucAfterPase.replaceAll(key, value);
    }
  });
  if (cell.keycongthuccot || cell.keycongthucdong) {
    try {
      return {
        key: cell.key,
        cthuc: eval(congthucAfterPase),
      };
    } catch (e) {
      console.log('error', e, congthucAfterPase);
      return {
        key: cell.key,
        cthuc: 0,
      };
    }
  }
  return {
    key: cell.key,
    cthuc: 0,
  };
};

const buildRows = (congthucChangeData, sheetData, debug, idBieuMau) => {
  //buildHeder
  let listRowAfterHeader =
    sheetData.header &&
    sheetData.header.reduce((arrRows, row, indexRow) => {
      let listCell = row.reduce((arrCells, cell, indexCell) => {
        arrCells.push({
          value: cell.key,
          index: cell.index * 1 - 1,
          key: `gridrow_${indexRow}_gridcell_${indexCell}`,
          colSpan: cell.colspan * 1,
          rowSpan: cell.rowspan * 1,
          type: 'TEXT',
          is: 'HEADER',
          style: boldCenter,
        });
        return arrCells;
      }, []);
      arrRows.push({
        index: indexRow,
        cells: listCell,
      });
      return arrRows;
    }, []);
  //buildContent
  let valueSoSanhDX = 0;
  let valueSoSanhTD = 0;
  let valueSoSanh = 0;
  let batDauSoSanh = false;
  let cellCongThuc = false;
  let listRowAfterContent =
    sheetData.keyData &&
    sheetData.keyData.reduce((arrRows, row, index) => {
      let indexRow = index + 6;
      let listCell = row.reduce((arrCells, cell, indexCell) => {
        let valueCell = debug
          ? cell.key + '_' + idBieuMau
          : getValueCellWithFormat(idBieuMau, congthucChangeData, cell, sheetData.data, sheetData.congthuccot, sheetData.congthucdong);
        if (cell.keycongthuccot || cell.keycongthucdong) {
          //  console.log("valueCell", cell.key,cell.keycongthuccot, cell.keycongthucdong)
          cellCongThuc = true;
        }
        if (cell.is === 'CELLTD') {
          valueSoSanhDX = cell.key * 1;
          batDauSoSanh = true;
        }
        if (batDauSoSanh && cell.is === 'CELL') {
          valueSoSanhTD = valueCell * 1;
        }
        if (batDauSoSanh && cell.is === 'CELLSS') {
          valueSoSanh = valueSoSanhDX - valueSoSanhTD;
          batDauSoSanh = false;
        }
        let isChuThich = 0;
        if (cell.is === 'CELL') {
          let dataSplit = cell.key.split('_');
          isChuThich = dataSplit[12] * 1;
        }
        arrCells.push({
          value: cell.is === 'CELLSS' ? valueSoSanh : valueCell,
          format:
            ((cell.is === 'CELL' && isChuThich == 0) ||
              (cell.is === 'CELL' && cellCongThuc && isChuThich == 1) ||
              cell.is === 'CELLTD' ||
              cell.is === 'CELLSS') &&
            '_( #,###.#_);_( (#,###.#);_( "-"??_);_(@_)',
          isChuThich: isChuThich,
          index: cell.index * 1 - 1,
          key: `gridrow_${indexRow}_gridcell_${indexCell}`,
          keyDef: cell.key,
          keyPhanRa: (cell.keyPhanRa && cell.keyPhanRa) || [],
          type: cell.type,
          is: cell.is,
          colSpan: cell.colspan * 1,
          rowSpan: cell.rowspan * 1,
          idCol: (cell.idCol && cell.idCol) || undefined,
          idRow: (cell.idRow && cell.idRow) || undefined,
          style: (cell.is === 'COL' && boldCenter) || (cell.is === 'ROW' && boldLeft),
        });
        return arrCells;
      }, []);
      arrRows.push({
        index: indexRow,
        cells: listCell,
      });
      return arrRows;
    }, listRowAfterHeader);
  return listRowAfterContent;
};

export const buildSheetDataFromOldData = bieumaus => {
  return bieumaus.reduce((arr, item, key) => {
    if (!isEmpty(item.datavaluebieumau)) {
      console.log(JSON.parse(item.datavaluebieumau));
      const rowData = JSON.parse(item.datavaluebieumau);
      arr.push({
        name: `${key}. ${item.ten}`,
        key: `bieumau_${item.id}`,
        keyDefCode: item.ma,
        keyDefId: item.id,
        usedRange: {},
        maxRows: rowData.length,
        isProtected: item.action !== 'CHUYEN' && item.daluukho != 1 ? false : true,
        protectSettings: {
          selectCells: true,
          formatCells: false,
          formatRows: false,
          formatColumns: false,
          insertLink: false,
        },
        activeCell: 'A1',
        selectedRange: 'A1',
        rows: rowData,
      });
    }
    return arr;
  }, []);
};
