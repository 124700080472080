import React from "react";
export default function SectionMenu({ name }) {
  return (
    <>
      {/* begin::section */}
      <li className="menu-section ">
        <h4 className="menu-text">{name}</h4>
        <i className="menu-icon flaticon-more-v2"></i>
      </li>
      {/* end:: section */}
    </>
  );
}
