export default function AsideMenuConfig() {
  return [
    // {
    //   type: 'HOMEPAGE', // HOMEPAGE | MENU | SECTION
    //   url: '/dashboard',
    //   name: 'Trang chủ',
    //   svgIcon: '/media/svg/icons/Design/Layers.svg',
    // },
    {
      type: 'SECTION',
      name: 'Quản trị hệ thống',
    },
    {
      type: 'MENU',
      url: '/manage',
      name: 'Quản trị',
      svgIcon: '/media/svg/icons/General/Settings-2.svg',
      children: [
        {
          type: 'MENU',
          url: '/manage/organizations',
          name: 'Cơ quan',
          config: 'configAppModuleOrganizations',
        },
        {
          type: 'MENU',
          url: '/manage/departments',
          name: 'Phòng ban',
          config: 'configAppModuleDepartments',
        },
        {
          type: 'MENU',
          url: '/manage/users',
          name: 'Người dùng',
          config: 'configAppModuleUsers',
        },
        {
          type: 'MENU',
          url: '/manage/user-groups',
          name: 'Nhóm người dùng',
          config: 'configAppModuleUserGroups',
        },
        {
          type: 'MENU',
          url: '/manage/logsystem',
          name: 'Log hệ thống',
          config: 'configAppModuleLogSystem',
        },
        {
          type: 'MENU',
          url: '/manage/category',
          name: 'Danh mục',
          children: [
            {
              type: 'MENU',
              url: '/manage/category/danhmucdungchung',
              name: 'Danh mục - Dùng Chung',
              config: 'configAppModuleDmDungChung',
            },
            {
              type: 'MENU',
              url: '/manage/category/danhmucdungchungloai',
              name: 'Dùng Chung - Loại',
              config: 'configAppModuleDmDungChungLoai',
            },
            {
              type: 'MENU',
              url: '/manage/category/chuong',
              name: 'Chương',
              config: 'configAppModuleDmChuong',
            },
            {
              type: 'MENU',
              url: '/manage/category/loai-khoan',
              name: 'Loại - Khoản',
              config: 'configAppModuleDmLoaiKhoan',
            },
            {
              type: 'MENU',
              url: '/manage/category/muc-tieumuc',
              name: 'Mục - Tiểu mục',
              config: 'configAppModuleDmMucTieuMuc',
            },
            {
              type: 'MENU',
              url: '/manage/category/ctrinh-duan',
              name: 'Chương trình - Dự án',
              config: 'configAppModuleDmChuongTrinhDuAn',
            },
            {
              type: 'MENU',
              url: '/manage/category/nguonvon',
              name: 'Nguồn vốn',
              config: 'configAppModuleDmNguonVon',
            },
            {
              type: 'MENU',
              url: '/manage/category/tieuchi-cot',
              name: 'Tiêu chí cột',
              config: 'configAppModuleDmTieuChiCot',
            },
            {
              type: 'MENU',
              url: '/manage/category/tieuchi-dong',
              name: 'Tiêu chí dòng',
              config: 'configAppModuleDmTieuChiDong',
            },
            {
              type: 'MENU',
              url: '/manage/category/thongtu-nghidinh',
              name: 'Thông tư - Nghị định',
              config: 'configAppModuleDmThongTu',
            },
          ],
        },
      ],
    },
    {
      type: 'MENU',
      url: '/system',
      name: 'Hệ thống',
    },
    // {
    //   type: 'SECTION',
    //   name: 'Quản lý biểu mẫu',
    // },
    {
      type: 'MENU',
      url: '/tintuc',
      name: 'Tin tức',
      config: 'configAppModuleDmTinTuc',
      svgIcon: '/media/svg/icons/General/Bookmark.svg',
    },
    {
      type: 'MENU',
      url: '/nsnn/bieumaukho',
      name: 'Biểu mẫu',
      config: 'configAppModuleNSNNBieuMauKho',
      svgIcon: '/media/svg/icons/Home/Library.svg',
    },
    {
      type: 'SECTION',
      name: 'Tài liệu',
    },
    {
      type: 'MENU',
      url: '/ws/p/dutoan',
      name: 'Dự toán',
      svgIcon: '/media/svg/icons/Communication/Archive.svg',
    },
    {
      type: 'MENU',
      url: '/ws/p/quyettoan',
      name: 'Quyết toán',
      svgIcon: '/media/svg/icons/Communication/Mail-box.svg',
    },
    {
      type: 'MENU',
      url: '/ws/p/thtcnsnn',
      name: 'Báo cáo tình hình thu chi',
      svgIcon: '/media/svg/icons/Communication/Flag.svg',
    },
    {
      type: 'MENU',
      url: '/thongke',
      name: 'Thống kê',
      svgIcon: '/media/svg/icons/Shopping/Chart-pie.svg',
      config: 'configAppModuleThongKe',
      isNotCRUD: true,
    },
    // {
    //   type: 'SECTION',
    //   name: 'Thông tin hỗ trợ',
    // },
    // {
    //   type: 'MENU',
    //   url: '/tintuc',
    //   name: 'Tin tức',
    //   config: 'configAppModuleDmTinTuc',
    // },
    {
      type: 'MENU',
      url: '/support',
      svgIcon: '/media/svg/icons/Code/Question-circle.svg',
      name: 'Hỗ trợ',
    },
    // {
    //   type: 'SECTION',
    //   name: 'Cơ sở dữ liệu tài chính',
    // },
    // {
    //   type: 'MENU',
    //   url: '/qtc-csdl',
    //   name: 'Quỹ tài chính',
    //   svgIcon: '/media/svg/icons/Communication/Write.svg',
    //   children: [
    //     {
    //       type: 'MENU',
    //       url: '/qtc-csdl/quytaichinh',
    //       name: 'Quỹ tài chính',
    //       config: 'configAppModuleQuyTaiChinh',
    //     },
    //     {
    //       type: 'MENU',
    //       url: '/qtc-csdl/baocaoquytaichinh',
    //       name: 'Báo cáo quỹ tài chính',
    //       configTCDN: 'configAppModileBaoCaoQTC',
    //     },
    //     {
    //       type: 'MENU',
    //       url: '/qtc-csdl/tieuchuanvay',
    //       name: 'Tiêu chuẩn vay',
    //       configTIEUCHUANVAY: true,
    //     },
    //     {
    //       type: 'MENU',
    //       url: '/qtc-csdl/thongtinvay',
    //       name: 'Thông tin vay',
    //       configTIEUCHUANVAY: true,
    //     },
    //     {
    //       type: 'MENU',
    //       url: '/qtc-csdl/baocaotinhhinhnonsdp',
    //       name: 'Báo cáo tình hình nợ NSĐP',
    //       configTIEUCHUANVAY: true,
    //     },
    //   ],
    // },
    // {
    //   type: 'MENU',
    //   url: '/dn-csdl',
    //   name: 'Quản lý tài chính doanh nghiệp',
    //   svgIcon: '/media/svg/icons/Code/Compiling.svg',
    //   children: [
    //     {
    //       type: 'MENU',
    //       url: '/dn-csdl/danhsachdoanhnghiep',
    //       name: 'Danh sách doanh nghiệp',
    //       configKeyDN: 'configKeyDoanhNghiep',
    //       urlQTC: 'dn-csdl/taichinhdoanhnghiep',
    //     },
    //     {
    //       type: 'MENU',
    //       url: '/dn-csdl/baocaothongke',
    //       name: 'Báo cáo thống kê',
    //       configTCDN: 'configAppModileBaoCaoTK',
    //     },
    //   ],
    // },
  ];
}
