/* eslint-disable eqeqeq */
import {createSlice} from "@reduxjs/toolkit";
import { treeCustomRelatedBuild, nestedNoTree } from "../../../../../_metronic/_helpers";

const initialSpecificationsState = {
  appModuleId: null,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  treeDataPID:null,
  specificationForEdit: undefined,
  lastError: null,
  config:null,
  relatedEntities:{}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const appBaseCRUDListSpecsSlice = createSlice({
  name: "crudBaseSpecs",
  initialState: initialSpecificationsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSpecificationById
    specificationFetched: (state, action) => {
      state.actionsLoading = false;
      state.specificationForEdit = action.payload.specificationForEdit;
      state.error = null;
    },
    // findSpecifications
    specificationsFetched: (state, action) => {
      const { totalCount, entities, config, appModuleId } = action.payload;
      state.listLoading = false;
      state.error = null;
      let dataEntities = nestedNoTree(entities, config);
      state.entities = dataEntities.entities;
      state.config = config;
      if( state.appModuleId !== appModuleId ){
        state.appModuleId = appModuleId;
        state.treeDataPID = treeCustomRelatedBuild( entities, config);
      }
      state.totalCount = totalCount;
    },
    // createSpecification
    specificationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const stateEntities = [...state.treeDataPID.entities, action.payload.specification];
      state.entities =stateEntities
      state.treeDataPID = treeCustomRelatedBuild( stateEntities, state.config);
    },
    // updateSpecification
    specificationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.map(entity => {
        if (entity.id == action.payload.specification.id) {
          return action.payload.specification;
        }
        return entity;
      });
      state.entities =stateEntities
      state.treeDataPID = treeCustomRelatedBuild( stateEntities, state.config);
    },
    // deleteSpecification
    specificationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(el => el.id !== action.payload.id);
      state.entities =stateEntities
      state.treeDataPID = treeCustomRelatedBuild( stateEntities, state.config);
    },
    // deleteSpecifications
    specificationsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
      state.entities =stateEntities
      state.treeDataPID = treeCustomRelatedBuild( stateEntities, state.config);
    },
    // fetchListRelatedSpecifications
    listRelatedSpecificationsFetched: (state, action) => {
      const { entities, nameRelated } = action.payload;
      state.listLoading = false;
      state.error = null;
      let relatedEntities = {
        ...state.relatedEntities,
        [nameRelated]:  entities
      }
      state.relatedEntities = relatedEntities;
    },
  }
});
