import { curry } from 'ramda';
import { flatten, uniq, isEmpty } from 'lodash';
import { getValla, getVallaOther } from './TreeVirHelpers';

export const hasChildren = node => {
  return typeof node === 'object' && typeof node.children !== 'undefined' && node.children.length > 0;
};

export const Tree = {
  reduce: curry(function reduce(reducerFn, init, node) {
    const acc = reducerFn(init, node);
    if (!hasChildren(node)) {
      return acc;
    }
    return node.children.reduce(Tree.reduce(reducerFn), acc);
  }),
  map: curry(function map(mapFn, node) {
    const newNode = mapFn(node);
    if (hasChildren(node)) {
      return newNode;
    }
    newNode.children = node.children.map(Tree.map(mapFn));
    return newNode;
  }),
};

function sumTypeFn(total, item, type) {
  return total + (item.type === type ? 1 : 0);
}
function flattenToArrayFn(arr, { children, ...data }) {
  return arr.concat([{ ...data }]);
}
function addChildCountFn(node) {
  const countstr = hasChildren(node) ? ` (${node.children.length})` : '';
  return {
    ...node,
    childCount: countstr,
  };
}

export const sumByType = (tree, type) => Tree.reduce(sumTypeFn, 0, tree, type);
export const flattenToArray = tree => Tree.reduce(flattenToArrayFn, [], tree);
export const addChildCount = tree => Tree.reduce(addChildCountFn, tree);

export const nestedChildren = (entities, mapParent = { cidProp: 'id', pidProp: 'pid', nameProp: 'ten' }) => {
  const idCode = (mapParent.codeProp && mapParent.codeProp) || mapParent.cidProp;

  /*
  slowed
  const getNestedChildren = (arr, parent)=>{
    let out = {treeData:[],entiObj:{}}
    for(let i in arr) {
      let itemReturn = {...arr[i]};
      let itemFlat= {...arr[i],children:[]};

      itemReturn[mapParent.cidProp] = arr[i][mapParent.cidProp]+""
      itemReturn[mapParent.pidProp] = arr[i][mapParent.pidProp]+""
      itemFlat[mapParent.cidProp] = arr[i][mapParent.cidProp]+""
      itemFlat[mapParent.pidProp] = arr[i][mapParent.pidProp]+""
      let idName = getValla(arr[i],mapParent.nameProp);
      let idNameOther = getVallaOther(arr[i], mapParent.nameProp,mapParent.otherName);
      if(arr[i][mapParent.pidProp]+"" === parent+"") {
        let children = getNestedChildren(arr, arr[i][mapParent.cidProp]+"")
        if(children.treeData.length>0) {
          itemReturn.children = children.treeData
          itemFlat.children = children.treeData.reduce((arrChild, itemChild) =>{
              arrChild.push(itemChild[idCode]+"")
              return arrChild
          },[])
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode]+"", // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode]+"",
            _name: idNameOther,// dùng cho tree select
          }
          out.entiObj = {...out.entiObj,...children.entiObj}
        }else{
          out.entiObj[arr[i][idCode]] = {
            ...itemFlat,
            value: arr[i][idCode]+"", // dùng cho tree select
            title: idNameOther, // dùng cho tree select
            label: idNameOther, // dùng cho tree select
            _cid: arr[i][idCode]+"",
            _name: idNameOther,// dùng cho tree select
          }
        }
        out.treeData.push({
          ...itemReturn,
          value: arr[i][idCode]+"", // dùng cho tree select
          title: idNameOther, // dùng cho tree select
          label: idNameOther, // dùng cho tree select
          _cid: arr[i][idCode]+"",
          _name: idNameOther,// dùng cho tree select
        })
      }
    }
    return out
  }
  */

  const getNestedChildren = (list, parent) => {
    var map = {},
      node,
      roots = [],
      rootsObj = {},
      i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i][mapParent.cidProp]] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].childrenObj = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      let idName = getValla(list[i], mapParent.nameProp);
      let idNameOther = getVallaOther(list[i], mapParent.nameProp,mapParent.otherName);
      node = {
        ...list[i],
        value: list[i][idCode] + '', // dùng cho tree select
        title: idNameOther, // dùng cho tree select
        label: idNameOther, // dùng cho tree select
        _cid: list[i][idCode] + '',
        _name: idNameOther, // dùng cho tree select
        expanded: false, // dùng cho tree sortable cho dinh nghia mau
      };
      node[mapParent.cidProp] = list[i][mapParent.cidProp] + '';
      node[mapParent.pidProp] = list[i][mapParent.pidProp] + '';

      if (node[mapParent.pidProp] + '' !== parent + '') {
        // if you have dangling branches check that map[node[mapParent.pidProp]]] exists
        if (list[map[node[mapParent.pidProp]]] && list[map[node[mapParent.pidProp]]].children) {
          list[map[node[mapParent.pidProp]]].children.push(node);
        } else {
          let obj = { ...list[map[node[mapParent.pidProp]]], children: [node] };
          list[map[node[mapParent.pidProp]]] = obj;
        }

        list[map[node[mapParent.pidProp]]] &&
          list[map[node[mapParent.pidProp]]].childrenObj &&
          list[map[node[mapParent.pidProp]]].childrenObj.push(node[idCode]);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      } else {
        roots.push(node);
        let obj = { ...node, children: node.childrenObj };
        delete obj.childrenObj;
        rootsObj[node[idCode]] = obj;
      }
    }
    return { treeData: roots, entiObj: rootsObj };
  };

  const entitiesRaw = [...entities];
  const treeBuild = entitiesRaw
    ? getNestedChildren(entitiesRaw, '0')
    : {
        treeData: [],
        entiObj: {},
      };
  //bắt buộc dùng string nhé để không lỗi
  const entitiesTreeDefault = {
    entities: [],
    ...treeBuild,
    treeRowKeys: {},
  };

  const findParents = (node, searchForName) => {
    // // If current node name matches the search name, return
    // // empty array which is the beginning of our parent result
    if (node[mapParent.cidProp] === searchForName) {
      return [];
    }
    // Otherwise, if this node has a tree field/value, recursively
    // process the nodes in this tree array
    if (Array.isArray(node.children)) {
      const parentNode = node;
      for (var treeNode of node.children) {
        // Recursively process treeNode. If an array result is
        // returned, then add the treeNode.name to that result
        // and return recursively
        const childResult = findParents(treeNode, searchForName);
        if (Array.isArray(childResult)) {
          if (treeNode[mapParent.pidProp] && treeNode[mapParent.pidProp] !== '' && treeNode[mapParent.pidProp] !== '0') {
            return [parentNode[idCode]].concat(childResult);
          } else {
            return childResult;
          }
        }
      }
    }
  };

  const entitiesTree = entities
    ? entities.reduce((arr, item) => {
        let idName = getValla(item, mapParent.nameProp);
        let idNameOther = getVallaOther(item, mapParent.nameProp,mapParent.otherName);
        let itemReturn = {
          ...item,
          value: item[idCode] + '',
          title: idNameOther,
          label: idNameOther,
          _cid: item[idCode] + '',
          _name: idNameOther,
        };
        itemReturn[mapParent.cidProp] = item[mapParent.cidProp] + '';
        itemReturn[mapParent.pidProp] = item[mapParent.pidProp] + '';
        arr.entities.push(itemReturn);
        arr.entiObj[item[idCode]] = itemReturn; // dùng cho tree select
        arr.treeRowKeys[item[idCode]] = findParents({ children: arr.treeData }, item[mapParent.cidProp] + '');
        return arr;
      }, entitiesTreeDefault)
    : entitiesTreeDefault;

  return {
    entitiesTree,
    valueKey: idCode,
    rowKeys: () => Object.keys(this.entitiesTree.treeRowKeys),
    expandableRowKeys: () => uniq(flatten(Object.values(this.entitiesTree.treeRowKeys)), true),
    nonExpandable: () => this.rowKeys().filter(x => !this.expandableRowKeys().includes(x)),
    hiddenRowKeys: () => this.rowKeys().filter(x => !isEmpty(this.entitiesTree.treeRowKeys[x])),
    treeRowChild: () =>
      this.rowKeys().reduce((obj, item) => {
        obj[item] = this.rowKeys().reduce((arr, itx) => {
          if (this.entitiesTree.treeRowKeys[itx] && this.entitiesTree.treeRowKeys[itx].includes(item)) {
            arr.push(itx);
          }
          return arr;
        }, []);
        return obj;
      }, {}),
  };
};

// export const nestedChildrenButNoTree =(entities, mapParent)=>{
//   let indentity = (mapParent && mapParent.cidProp) || "cid";
//   let indentityName = (mapParent && mapParent.nameProp) || "name";

//   const entitiesTreeDefault ={
//     entities:[],
//     entiObj:{},
//     treeData: entities,
//     treeRowKeys:{}
//   }

//   const entitiesTree = entities ? entities.reduce((arr, item) =>{
//     let idName = getValla(item,indentityName);
//     let idNameOther = getVallaOther(item, indentityName, undefined);
//     let itemReturn = {
//       ...item,
//       value: item[indentity] +"",
//       title: idNameOther,
//       label: idNameOther,
//       _cid: item[indentity]+"",
//       _name: idNameOther,
//     }
//     itemReturn[indentity] = item[indentity]+""
//     arr.entities.push(itemReturn);
//     arr.entiObj[item[indentity]] = itemReturn; // dùng cho tree select
//     arr.treeRowKeys[item[indentity]]=[]
//     return arr;
//   },entitiesTreeDefault) :entitiesTreeDefault;

//   return {
//     entitiesTree,
//     valueKey:indentity,
//     rowKeys: ()=>(Object.keys(this.entitiesTree.treeRowKeys)),
//     expandableRowKeys: ()=>(uniq(flatten(Object.values(this.entitiesTree.treeRowKeys)),true)),
//     nonExpandable: ()=>(this.rowKeys().filter(x => !this.expandableRowKeys().includes(x))),
//     hiddenRowKeys: ()=>(this.rowKeys().filter(x =>!isEmpty(this.entitiesTree.treeRowKeys[x]))),
//     treeRowChild: ()=>(this.rowKeys().reduce((obj, item)=>{
//       obj[item] = this.rowKeys().reduce((arr, itx)=>{
//         if(this.entitiesTree.treeRowKeys[itx] && this.entitiesTree.treeRowKeys[itx].includes(item)){
//           arr.push(itx)
//         }
//         return arr
//       },[]);
//       return obj;
//     },{})),
//   }
// }
