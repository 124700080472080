import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import objectPath from 'object-path';
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';
// Import Layout components
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import SubHeader from './subheader/SubHeader';
// import {QuickPanel} from "./extras/offcanvas/QuickPanel";
import { QuickUser } from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
// import {StickyToolbar} from "./extras/StickyToolbar";
//import { Snackbar, makeStyles,} from '@material-ui/core';
import axios from 'axios';
// import SockJS from 'sockjs-client'; // Note this line
// import Stomp from 'stompjs';
// import MuiAlert from '@material-ui/lab/Alert';
import * as actions from '../../../app/modules/AppBaseCRUD/_redux/list/listActions';
// import { Toast } from 'react-bootstrap';

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
// const SOCKET_PORT = `//localhost:8070/notification`;
// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));
export function Layout({ children }) {
  const dispatch = useDispatch();
  // const { user } = useSelector(state => state.auth);
  // const listMessage = useSelector(state => state.baseCRUDList.messageNotification);
  // const [showAlert, setshowAlert] = useState(false);
  // const [show, setShow] = useState(false);
  // const [datacheck, setdatacheck] = useState({});
  useEffect(() => {
    axios.post(`/services/qtht/api/notification/message/20`).then(res => {
      if (res.status === 200) {
        dispatch(actions.setMessageNotification(res.data.result.entities));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Phần gọi socket lấy data.
  // useEffect(() => {
  //   const socket = new SockJS(SOCKET_PORT);
  //   const stompClient = Stomp.over(socket);
  //   stompClient && stompClient.connected && stompClient.disconnect();
  //   stompClient.debug = null;
  //   stompClient.connect({}, () => {
  //     // eslint-disable-next-line array-callback-return
  //     user.nhomnguoidung &&
  //       user.nhomnguoidung.map((e, i) => {
  //         stompClient.subscribe(`/channel/${user.macoquan}_${e}`, message => {
  //           const data = JSON.parse(message.body);
  //           if (!data.daXem) {
  //             data.daXem = 0;
  //             dispatch(actions.setMessageNotification([...listMessage, data]));
  //             setshowAlert(true);
  //           }
  //         });
  //       });
  //   });
  //   return () => {
  //     stompClient && stompClient.connected && stompClient.disconnect();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setshowAlert(false);
  // };

  // END Phần gọi socket lấy data.
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);
  // const classes = useStyles();
  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*begin::Content*/}
            <div id="kt_content" className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>
              {layoutProps.subheaderDisplay && <SubHeader />}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>{children}</div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
            {/* <div
              aria-live="polite"
              aria-atomic="true"
              style={{
                position: 'relative',
                minHeight: '200px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <Toast onClose={() => setShow(false)} show={show} delay={700000} autohide>
                  <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">Bootstrap</strong>
                    <small>just now</small>
                  </Toast.Header>
                  <Toast.Body>Thông báo mới!</Toast.Body>
                </Toast>
                <div className={classes.root}>
                  <Snackbar
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={showAlert}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {' '}
                      Thông báo mới!{' '}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div> */}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      {/* <QuickPanel/> */}
      <ScrollTop />
      {/* <StickyToolbar/> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
