import React from "react";
import {useField, useFormikContext} from "formik";
import { Editor } from "@tinymce/tinymce-react";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function TextAreaEditor({asteriskSign,...props}) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label} {asteriskSign && <i style={{color:"red"}}>*</i>}</label>}
      <Editor
      apiKey='h0v8kz1clxerx9ccwm3h8bn8ho8hc4hfjmwwmwezy9bwrt16'
        className={`form-control ${getFieldCSSClasses(touched[field.name], errors[field.name])}`}
        {...field}
        {...props}
        init={{
          height: 200,
          menubar: false,
          branding:false
        }}
        value={field.value}
        onEditorChange={(content, editor)=> {
          setFieldValue(field.name, content);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
      <div className="invalid-datepicker-feedback">
        {errors[field.name].toString()}
      </div>
    ) : (null)}
    </>
  );
}
