import { createSlice } from '@reduxjs/toolkit';

const initialPoolSliceState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  dataPool:{},
};
export const callTypes = {
  list: 'pool',
  action: 'action',
};

export const poolSlice = createSlice({
  name: 'poolApp',
  initialState: initialPoolSliceState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    setDataPool: (state, action) => {
      state.dataPool = {
        ...state.dataPool,
        ...action.payload.data
      };
    },
  },
});
