/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { v4 as uuidv4 } from 'uuid';

export function UniHeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    const dataHeaderMenu =  [{
      type: 'MENU',
      url: '/ws/p/dutoan',
      name: 'Dự toán',
    },
    {
      type: 'MENU',
      url: '/ws/p/quyettoan',
      name: 'Quyết toán',
    },
    {
      type: 'MENU',
      url: '/ws/p/thtcnsnn',
      name: 'Báo cáo tình hình thu chi',
    }]


    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {dataHeaderMenu.map((item, key) =>(
              <li className={`menu-item menu-item-rel ${getMenuItemActive(item.url)}`} key={uuidv4()}>
                <NavLink className="menu-link" to={item.url}>
                    <span className="menu-text">{item.name}</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            ))}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
