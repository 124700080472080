import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import { BuilderPage } from './pages/BuilderPage';
import { DashboardPage } from './pages/DashboardPage';

// const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'));
// const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'));
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
const ManagePage = lazy(() => import('./pages/ManagePage'));
const TinTucPage = lazy(() => import('./pages/TinTucPage'));
const BieuMauPage = lazy(() => import('./pages/BieuMauPage'));
const UserProfilePage = lazy(() => import('./modules/UserProfile/UserProfilePage'));
const WorkspacePage = lazy(() => import('./modules/Workspace/WorkspacePage'));
const HoTroPage = lazy(() => import('./pages/HoTroPage'));
const ThongKePage = lazy(() => import('./pages/ThongKePage'));
// const QuyTaiChinhPage = lazy(() => import('./modules/QuyTaiChinh/QuyTaiChinhPage'));
// const BaoCaoQuyTaiChinhPage = lazy(() => import('./modules/QuyTaiChinh/BaoCaoQuyTaiChinhPage'));
const TieuChuanVay = lazy(() => import('./modules/QuyTaiChinh/TieuChuanVayPage'));
const ThongTinVay = lazy(() => import('./modules/QuyTaiChinh/ThongTinVayPage'));
// const BaoCaoTinhHinhNoNSDP = lazy(() => import('./modules/QuyTaiChinh/BaoCaoTinhHinhNoNSDPPage'));
// const DanhSachDoanhNghiep = lazy(() => import('./modules/QuyTaiChinh/DanhSachDoanhNghiepPage'));
// const TaiChinhDoanhNghiep = lazy(() => import('./modules/QuyTaiChinh/TaiChinhDoanhNghiepPage'));
// const BaoCaoThongKe = lazy(() => import('./modules/QuyTaiChinh/BaoCaoThongKePage'));
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* <Route path="/e-commerce" component={ECommercePage}/> */}

        <Route path="/manage" component={ManagePage} />
        <Route path="/nsnn" component={BieuMauPage} />

        <Route path="/user/profile" component={UserProfilePage} />
        <Route path="/ws" component={WorkspacePage} />
        <Route path="/tintuc" component={TinTucPage} />
        <Route path="/thongke" component={ThongKePage} />
        <Route path="/support" component={HoTroPage} />
        {
          // <Redirect exact from="/qtc-csdl" to="/qtc-csdl/quytaichinh" />
        }
        {/* <Route path="/qtc-csdl/quytaichinh" component={QuyTaiChinhPage} /> */}
        {/* <Route path="/qtc-csdl/baocaoquytaichinh" component={BaoCaoQuyTaiChinhPage} /> */}
        {/* <Route path="/qtc-csdl/tieuchuanvay" component={TieuChuanVay} />
        <Route path="/qtc-csdl/thongtinvay" component={ThongTinVay} /> */}
        {/* <Route path="/qtc-csdl/baocaotinhhinhnonsdp" component={BaoCaoTinhHinhNoNSDP} /> */}
        {
          // <Redirect exact from="/dn-csdl" to="/dn-csdl/danhsachdoanhnghiep" />
        }
        {/* <Route path="/dn-csdl/danhsachdoanhnghiep" component={DanhSachDoanhNghiep} />
        <Route path="/dn-csdl/taichinhdoanhnghiep" component={TaiChinhDoanhNghiep} />
        <Route path="/dn-csdl/baocaothongke" component={BaoCaoThongKe} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
