import axios from "axios";

// CREATE =>  POST: add a new item to the server
export function createItem(CUSTOM_URL, item) {
  return axios.post(CUSTOM_URL, item);
}

// READ
export function getAllList(CUSTOM_URL) {
  return axios.get(CUSTOM_URL);
}

export function getItemById(CUSTOM_URL, itemId) {
  return axios.get(`${CUSTOM_URL}/${itemId}`);
}

export function getLogByUsername(CUSTOM_URL, itemRequest) {
  console.log(`itemRequest`, itemRequest)

  return axios.post(`${CUSTOM_URL}/log`, itemRequest);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findList(CUSTOM_URL, queryParams) {
  return axios.post(`${CUSTOM_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the item on the server
export function updateItem(CUSTOM_URL, item) {
  return axios.put(`${CUSTOM_URL}/${item.id}`, item);
}

// UPDATE Status
export function updateStatusForList(CUSTOM_URL, ids, status) {
  // return axios.post(`${CUSTOM_URL}/updateStatusForList`, {
  //   ids,
  //   status
  // });
}

// DELETE => delete the item from the server
export function deleteItem(CUSTOM_URL, itemId) {
  return axios.post(`${CUSTOM_URL}/${itemId}/del`);
}

// DELETE List by ids
export function deleteList(CUSTOM_URL, ids) {
  return axios.post(`${CUSTOM_URL}/deleteList`, { ids });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findListRelated(CUSTOM_URL, queryParams) {
  return axios.get(CUSTOM_URL, { queryParams });
}
// không xóa phần này
export function logsystemdeletefixed(CUSTOM_URL) {
  return axios.post(`${CUSTOM_URL}/del`);
}

