/* eslint-disable no-loop-func */
export const checkPhanQuyenChucNang = (url, user, type, nhomnguoidung) => {

  const urlSplit = url.split('/');
  const keyId = urlSplit[urlSplit.length - 1].toUpperCase() + type.toUpperCase();
  let status = false;
  if (user.chucnang && user.chucnang.includes(keyId)) {
    status = true;
  }
  if (user.authorities && user.authorities.includes('ROLE_ADMIN')) {
    status = true;
  }
  if (urlSplit[urlSplit.length - 1].toUpperCase() === 'SUPPORT') {
    status = true;
  }
  const nhomnguoidungbyuser = (user.nhomnguoidung && user.nhomnguoidung) || '';
  const nhomNgDung = (nhomnguoidung && nhomnguoidung.entities) || [];

  for (var i = 0; i <= nhomnguoidungbyuser.length; i++) {
    let nhngdung = nhomNgDung.length > 0 && nhomNgDung.filter(e => e.manhomnguoidung === nhomnguoidungbyuser[i]);
    if (nhngdung && nhngdung.length > 0) {
      if (nhngdung[0].chucnang.includes(keyId)) {
        status = true;
      }
    }
  }
  return status;
};
