/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler, DropdownMenu1 } from '../../dropdowns';
import { shallowEqual, useSelector } from 'react-redux';
import { toAbsoluteUrl } from './../../../_helpers/AssetsHelpers';
import { Link } from 'react-router-dom';

export function ListsWidget4({ className }) {
  const { workspace } = useSelector(
    state => ({
      workspace: state.auth.relatedEntities.workSpace,
    }),
    shallowEqual
  );
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Bộ tài liệu cá nhân</h3>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" drop="down" alignRight>
                <Dropdown.Toggle
                  id="dropdown-toggle-top2"
                  variant="transparent"
                  className="btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75">
                  Create
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <DropdownMenu2 />
                </Dropdown.Menu>
              </Dropdown>
          </div> */}
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          {workspace &&
            workspace.MYWS.map((it, itkey) => (
              <div className="d-flex align-items-center mb-10" key={itkey}>
                <span className="bullet bullet-bar bg-success align-self-stretch"></span>

                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                  <input type="checkbox" name="" onChange={() => {}} value="1" />
                  <span></span>
                </label>

                <div className="d-flex flex-column flex-grow-1">
                  <Link
                    to={`/ws/detail/${it.makieu.toLowerCase()}/${it.ma}`}
                    className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                  >
                    {it.ten}
                  </Link>
                </div>
                {/* <ItemDropdown item="" /> */}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const ItemDropdown = ({ item }) => {
  return (
    <>
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-toggle-top"
          className="btn btn-hover-light-primary btn-sm btn-icon"
          as={DropdownCustomToggler}
        >
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
