import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ asteriskSign, ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const formatDateSel = (dateSelected) => {
    let datet = new Date(dateSelected);
    let dd = datet.getDate();
    let mm = datet.getMonth()+1;
    let yyyy = datet.getFullYear();
    return dd+'/'+mm+'/'+yyyy;
  };

  return (
    <>
      {props.label && <label>{props.label} {asteriskSign && <i style={{color:"red"}}>*</i>}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        dateFormat="dd/MM/yyyy"
        {...props}
        value={(field.value && formatDateSel(field.value)) || null}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (null)}
      {/* <div className="feedback">
        Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
      </div> */}
    </>
  );
}
