import React from "react";
import {useField, useFormikContext} from "formik";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};
export function InputFile({asteriskSign,...props}) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label} {asteriskSign && <i style={{color:"red"}}>*</i>}</label>}
      <input
        type='file'
        className={`form-control ${getFieldCSSClasses(touched[field.name], errors[field.name])}`}
        {...props}
        onChange={(event) => {
          setFieldValue(field.name,event.currentTarget.files[0]);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
      <div className="invalid-datepicker-feedback">
        {errors[field.name].toString()}
      </div>
    ) : (null)}
    </>
  );
}
