import { createSlice } from '@reduxjs/toolkit';
import { buildSheets, treeCustomRelatedBuild, buildSheetDataFromOldData } from '../../../../../_metronic/_helpers';
const today = new Date();

function getNam() {
  const config = {
    ednbmId: 'namNganSach',
    ednbmName: 'Năm ngân sách',
    colorVariant: 'primary',
  };
  const nams = [];
  for (var i = 2018; i <= today.getFullYear() + 3; i++) {
    nams.push({
      cid: i,
      name: i,
      value: i,
      title: i,
      label: i,
    });
  }
  let objData = { ...treeCustomRelatedBuild(nams, config, false), config };
  return objData;
}
function getThang() {
  let thangs = [];
  for (var i = 1; i <= 12; i++) {
    thangs.push({
      id: i,
      cid: i,
      name: 'Tháng ' + i,
      value: i,
      title: 'Tháng ' + i,
      label: 'Tháng ' + i,
    });
  }
  let config = {
    ednbmId: 'thangNganSach',
    ednbmName: 'Tháng ngân sách',
    colorVariant: 'success',
  };
  let objData = { ...treeCustomRelatedBuild(thangs, config, false), config };
  return objData;
}

const initialListState = {
  loading: false,
  actionsLoading: false,
  lastError: null,

  template: null,
  workspaceForEdit: null,
  workspace: {
    MYWS: [],
  },
  relatedEntities: {},
  itemForEdit: {},
  bieumauActive: null,
  activeSheetIndex: 0,
  dataBieuMau: {},
  bieumauSheets: {
    sheetData: [],
    entry: {},
  },
  conflictKey: [],
  coQuan: [],
  nhomNguoiDung: [],
  dinhNghiaBieuMau: {
    dataChooseUpDinhNghiaDongCot: [],
    dataUpDinhNghiaDongCot: [],
    checkDeletedataUpDinhNghiaDongCot: false,
    bieuMauForEdit: undefined,
    dinhNghiaDongCot: {
      dinhNghiaDong: undefined,
      dinhNghiaCot: undefined,
    },
    relatedEntities: {
      namNganSach: getNam(),
      thangNganSach: getThang(),
    },
  },
  itemWorkspaceTongHop: {},
  historyBieuMau: [],
  debug: false,
  congthucChange:{}
};

export const callTypes = {
  list: 'workspace',
  action: 'action',
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: initialListState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.loading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.loading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // createItemBoTaiLieu
    itemCreatedBoTaiLieu: (state, action) => {
      const { item } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      const stateEntities = [...state.workspace.MYWS, item];
      state.workspace.MYWS = stateEntities;
      state.workspaceForEdit = item;
    },

    listTemplateFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.template = entities;
    },

    listRelatedFetched: (state, action) => {
      const { entities, nameRelated } = action.payload;
      state.loading = false;
      state.error = null;
      let relatedEntities = {
        ...state.dinhNghiaBieuMau.relatedEntities,
        [nameRelated]: entities,
      };
      state.dinhNghiaBieuMau.relatedEntities = relatedEntities;
    },

    listWorkspaceFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.workspace = entities;
    },

    // getItemById

    itemFetched: (state, action) => {
      state.actionsLoading = false;
      state.itemForEdit = action.payload.itemForEdit;
      if (action.payload.itemForEdit.bieumau.length > 0) {
        state.bieumauActive = action.payload.itemForEdit.bieumau[0].id;
        state.activeSheetIndex = 0;
        state.bieumauSheets.sheetData = buildSheetDataFromOldData(action.payload.itemForEdit.bieumau);
      }
      state.error = null;
      state.debug = action.payload.debug;
    },

    changeBieuMauActive: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.bieumauActive = action.payload.bieumauActive;
      state.activeSheetIndex = action.payload.activeSheetIndex;
    },

    // getcoquan
    itemFetchedCoQuan: (state, action) => {
      state.actionsLoading = false;
      state.coQuan = action.payload.coQuan;
      state.error = null;
    },
    // getnhomnguoidung
    itemFetchedNhomNguoiDung: (state, action) => {
      state.actionsLoading = false;
      state.nhomNguoiDung = action.payload.nhomNguoiDung;
      state.error = null;
    },

    itemFetchedWSTongHop: (state, action) => {
      state.actionsLoading = false;
      state.itemWorkspaceTongHop = action.payload.itemWorkspaceTongHop;
      state.error = null;
    },

    historyBieuMau: (state, action) => {
      state.actionsLoading = false;
      state.historyBieuMau = action.payload.entities;
      state.error = null;
    },

    dataBieuMauDinhNghia: (state, action) => {
      const { defined, data, nameRelated } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.dinhNghiaBieuMau.bieuMauForEdit = defined;
      let dataBieuMau = {
        ...state.dataBieuMau,
        [nameRelated]: data,
      };
      state.dataBieuMau = dataBieuMau;
      let sheetOld = JSON.parse(JSON.stringify(state.bieumauSheets.sheetData));
      let sheetData =
        state.itemForEdit.bieumau &&
        buildSheets(sheetOld, state.itemForEdit.bieumau, state.itemForEdit.action, dataBieuMau, 'A1',  state.itemForEdit.daluukho, state.debug);
      state.bieumauSheets.sheetData = (sheetData && sheetData.dataSheet) || [];
      state.congthucChange = (sheetData && {...state.congthucChange, ...sheetData.congthucChange})
    },

    dataBieuMauDinhNghiaByWorker: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      let dataBieuMau = {
        ...state.dataBieuMau,
        ...data,
      };
      state.dataBieuMau = dataBieuMau;
      let sheetOld = JSON.parse(JSON.stringify(state.bieumauSheets.sheetData));
      let sheetData =
        state.itemForEdit.bieumau &&
        buildSheets(sheetOld, state.itemForEdit.bieumau, state.itemForEdit.action, dataBieuMau, 'A1', state.itemForEdit.daluukho, state.debug);
      state.bieumauSheets.sheetData = (sheetData && sheetData.dataSheet) || [];
      state.congthucChange = (sheetData && {...state.congthucChange, ...sheetData.congthucChange})
    },

    saveCellBieuMau: (state, action) => {
      const { dataCell, nameRelated, activeCell, sheets } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      // state.conflictKey = conflict;
      let dataBieuMau = {
        ...state.dataBieuMau[nameRelated].data,
        ...dataCell,
      };
      state.dataBieuMau[nameRelated].data = dataBieuMau;
      let sheetData =
        state.itemForEdit.bieumau &&
        buildSheets(sheets, state.itemForEdit.bieumau, state.itemForEdit.action, state.dataBieuMau, activeCell, state.itemForEdit.daluukho, state.debug);
      state.bieumauSheets.sheetData = (sheetData && sheetData.dataSheet) || [];
      state.congthucChange = (sheetData && {...state.congthucChange, ...sheetData.congthucChange})
    },
    dinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dinhNghiaBieuMau.dataUpDinhNghiaDongCot = action.payload;
    },
    checkDeletedinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dinhNghiaBieuMau.checkDeletedataUpDinhNghiaDongCot = action.payload;
    },
    deleteDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const data = state.dinhNghiaBieuMau.dataUpDinhNghiaDongCot.filter(i => i.key !== action.payload);
      state.dinhNghiaBieuMau.dataUpDinhNghiaDongCot = data;
    },
    setdataChooseDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dinhNghiaBieuMau.dataChooseUpDinhNghiaDongCot = [...state.dinhNghiaBieuMau.dataChooseUpDinhNghiaDongCot, action.payload];
    },
    resetdataChooseDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dinhNghiaBieuMau.dataChooseUpDinhNghiaDongCot = [];
    },
    itemFetchedBieuMau: (state, action) => {
      state.actionsLoading = false;
      state.dinhNghiaBieuMau.bieuMauForEdit = action.payload.itemForEdit;
      // if (action.payload.itemForEdit.bieumau.length > 0) {
      //   state.bieumauActive = action.payload.itemForEdit.bieumau[0].id;
      //   state.activeSheetIndex = 0;
      //   state.bieumauSheets.sheetData = buildSheetDataFromOldData(action.payload.itemForEdit.bieumau);
      // }
      state.error = null;
      state.debug = action.payload.debug;
    },

    checkDebug: (state, action) => {
      const { conflict } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.conflictKey = conflict;
      let sheetData =
        state.itemForEdit.bieumau &&
        buildSheets(
          state.bieumauSheets.sheetData,
          state.itemForEdit.bieumau,
          state.itemForEdit.action,
          state.dataBieuMau,
          'A1',
          state.itemForEdit.daluukho,
          state.debug,
          conflict
        );
      state.bieumauSheets.sheetData = (sheetData && sheetData.dataSheet) || [];
      state.congthucChange = (sheetData && {...state.congthucChange, ...sheetData.congthucChange})
    },
  },
});
