import React from "react";
import {
    MixedWidget1,
    ListsWidget4,
} from "../widgets";
export function Demo2Dashboard() {
    return (<>
            <div className="row">
                <div className="col-lg-6 col-xxl-4">
                    <MixedWidget1 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div>
            </div>
    </>);
}
