import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable'
const defaultState = {
  com: {
    selected: [],
    selectedAll: false,
    toogle: [0],
    selected_tieuchuanvay: [],
  },
  tieuchuanvay: {
    selected_tieuchuanvay: [],
    selectedAll_tieuchuanvay: false,
    toogle_tieuchuanvay: [0]
  },
}
export const commonQuyTaiChinhSlice = createSlice({
  name: 'QuyTaiChinhCommon',
  initialState: defaultState,
  reducers: {
    COMMON_RESET_COM: (state, action) => {
      return {
        com: {
          selected: [],
          selectedAll: false,
          toogle: [0]
        },
        tieuchuanvay: {
          selected_tieuchuanvay: [],
          selectedAll_tieuchuanvay: false,
          toogle_tieuchuanvay: [0]
        },
      }
    },

    COMMON_COM_SELECTED: (state, action) => {
      return Immutable.fromJS(state)
        .updateIn(['com', 'selected'], (selected) => selected.push(action.payload))
        .toJS()
    },
    COMMON_COM_DESELECTED: (state, action) => {
      return Immutable.fromJS(state)
        .updateIn(['com', 'selected'], (selected) => selected.filter((id) => id !== action.payload))
        .toJS()
    },
    COMMON_COM_SELECTEDALL: (state, action) => {
      return Immutable.fromJS(state)
        .update('com', (com) => com.set('selectedAll', true))
        .toJS()
    },
    COMMON_COM_DESELECTEDALL: (state, action) => {
      return Immutable.fromJS(state)
        .update('com', (com) => com.set('selectedAll', false))
        .toJS()
    },

    COMMON_RESET_COM_TIEUCHUANVAY: (state, action) => {
      return {
        com: {
          selected: [],
          selectedAll: false,
          toogle: [0]
        },
        tieuchuanvay: {
          selected_tieuchuanvay: [],
          selectedAll_tieuchuanvay: false,
          toogle: [0]
        },
      }
    },

    COMMON_COM_SELECTED_TIEUCHUANVAY: (state, action) => {
      return Immutable.fromJS(state)
        .updateIn(['tieuchuanvay', 'selected_tieuchuanvay'], (selected_tieuchuanvay) => selected_tieuchuanvay.push(action.payload))
        .toJS()
    },
    COMMON_COM_DESELECTED_TIEUCHUANVAY: (state, action) => {
      return Immutable.fromJS(state)
        .updateIn(['tieuchuanvay', 'selected_tieuchuanvay'], (selected_tieuchuanvay) => selected_tieuchuanvay.filter((id) => id !== action.payload))
        .toJS()
    },
    COMMON_COM_SELECTEDALL_TIEUCHUANVAY: (state, action) => {
      return Immutable.fromJS(state)
        .update('tieuchuanvay', (tieuchuanvay) => tieuchuanvay.set('selectedAll_tieuchuanvay', true))
        .toJS()
    },
    COMMON_COM_DESELECTEDALL_TIEUCHUANVAY: (state, action) => {
      return Immutable.fromJS(state)
        .update('tieuchuanvay', (tieuchuanvay) => tieuchuanvay.set('selectedAll_tieuchuanvay', false))
        .toJS()
    },
  },
});
