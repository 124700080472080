/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../../_helpers";

export default function ItemMenu({url, svgIcon, name, children}) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  const hasMenuSub = children.length > 0 ? true : false
  const dynamicPropsMenuItem = hasMenuSub ? {"data-menu-toggle":"hover"} : {}
  return (
    <>
      {/* begin::item menu */}
      <li
          className={`menu-item ${getMenuItemActive(url, hasMenuSub)} ${hasMenuSub?"menu-item-submenu":""}`}
          aria-haspopup="true"
          {...dynamicPropsMenuItem}
      >
        <NavLink className={`menu-link ${hasMenuSub?"menu-toggle":""}`} to={url}>
          {svgIcon?(
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(svgIcon)}/>
            </span>
          ):(
            <i className="menu-bullet menu-bullet-dot">
                <span/>
            </i>
          )}
          <span className="menu-text">{name}</span>
          {hasMenuSub && (<i className="menu-arrow"/>)}
        </NavLink>
        {hasMenuSub && (
          <div className="menu-submenu ">
            <i className="menu-arrow"/>
            <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">{name}</span>
                  </span>
                </li>
                {children}
            </ul>
          </div>
        )}
      </li>
      {/* end::item menu */}
    </>
  );
}
