/* eslint-disable eqeqeq */
import { createSlice } from '@reduxjs/toolkit';
import { treeCustomRelatedBuild, nestedNoTree } from '../../../../../_metronic/_helpers';

const initialDinhNghiaBieuMauState = {
  dataChooseUpDinhNghiaDongCot: [],
  dataUpDinhNghiaDongCot: [],
  checkDeletedataUpDinhNghiaDongCot: false,
  appModuleId: null,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  treeDataPID: null,
  itemForEdit: undefined,
  dinhNghiaDongCot: {
    dinhNghiaDong: undefined,
    dinhNghiaCot: undefined,
  },
  lastError: null,
  config: null,
  relatedEntities: {
    namNganSach: getNam(),
    thangNganSach: getThang(),
  },
};
function getNam() {
  const config = {
    ednbmId: 'namNganSach',
    ednbmName: 'Năm ngân sách',
    colorVariant: 'primary',
  };
  const treeData = [
    {
      cid: 3,
      name: 'Ba năm sau',
      value: 3,
      title: 'Ba năm sau',
      label: 'Ba năm sau',
    },
    {
      cid: 2,
      name: 'Hai năm sau',
      value: 2,
      title: 'Hai năm sau',
      label: 'Hai năm sau',
    },
    {
      cid: 1,
      name: 'Năm sau',
      value: 1,
      title: 'Năm sau',
      label: 'Năm sau',
    },
    {
      cid: 0,
      name: 'Năm hiện tại',
      value: 0,
      title: 'Năm hiện tại',
      label: 'Năm hiện tại',
    },
    {
      cid: -1,
      name: 'Năm trước',
      value: -1,
      title: 'Năm trước',
      label: 'Năm trước',
    },
    {
      cid: -2,
      name: 'Hai năm trước',
      value: -2,
      title: 'Hai năm trước',
      label: 'Hai năm trước',
    },
    {
      cid: -3,
      name: 'Ba năm trước',
      value: -3,
      title: 'Ba năm trước',
      label: 'Ba năm trước',
    },
  ];
  let objData = { ...treeCustomRelatedBuild(treeData, config, false), config };
  return objData;
}
function getThang() {
  let thangs = [
    {
      id: -1,
      cid: -1,
      name: 'Tháng tạo lập biểu mẫu',
      value: -1,
      title: 'Tháng tạo lập biểu mẫu',
      label: 'Tháng tạo lập biểu mẫu',
    },
  ];
  for (var i = 1; i <= 12; i++) {
    thangs.push({
      id: i,
      cid: i,
      name: 'Tháng ' + i,
      value: i,
      title: 'Tháng ' + i,
      label: 'Tháng ' + i,
    });
  }
  let config = {
    ednbmId: 'thangNganSach',
    ednbmName: 'Tháng ngân sách',
    colorVariant: 'success',
  };
  let objData = { ...treeCustomRelatedBuild(thangs, config, false), config };
  return objData;
}
//callTypes list is not name object manage. name object is list
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const dinhNghiaBieuMauSlice = createSlice({
  name: 'dinhNghiaBieuMau',
  initialState: initialDinhNghiaBieuMauState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getItemById
    itemFetched: (state, action) => {
      state.actionsLoading = false;
      state.itemForEdit = action.payload.itemForEdit;
      state.error = null;
    },
    // findList
    listFetched: (state, action) => {
      const { totalCount, entities, config, appModuleId } = action.payload;
      state.listLoading = false;
      state.error = null;
      let dataEntities = nestedNoTree(entities, config);
      state.entities = dataEntities.entities;
      state.config = config;
      if (state.appModuleId !== appModuleId) {
        state.appModuleId = appModuleId;
        state.treeDataPID = treeCustomRelatedBuild(entities, config);
      }
      state.totalCount = totalCount;
    },

    listFetchedInPool: (state, action) => {
      const { treeDataPID, config, appModuleId } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = treeDataPID && treeDataPID.entities;
      state.config = config;
      state.appModuleId = appModuleId;
      state.treeDataPID = treeDataPID;
      state.totalCount = treeDataPID && treeDataPID.entities && treeDataPID.entities.length;
    },

    // createItem
    itemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const stateEntities = [...state.entities, action.payload.item];
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // updateItem
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.treeDataPID.entities.map(entity => {
        if (entity.id == action.payload.item.id) {
          return action.payload.item;
        }
        return entity;
      });
      state.treeDataPID = treeCustomRelatedBuild(state.entities, state.config);
    },
    // deleteItem
    itemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(el => el.id !== action.payload.id);
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // deleteList
    listDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(el => !action.payload.ids.includes(el.id));
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // listUpdateState
    listStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      const stateEntities = state.treeDataPID.entities.map(entity => {
        if (ids.findIndex(id => id == entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // fetchListRelated
    listRelatedFetched: (state, action) => {
      const { entities, nameRelated } = action.payload;
      state.listLoading = false;
      state.error = null;
      let relatedEntities = {
        ...state.relatedEntities,
        [nameRelated]: entities,
      };
      state.relatedEntities = relatedEntities;
    },

    //add or update dinh nghia
    dinhNghiaAddOrUpdated: (state, action) => {
      const { value, nameRelated, tabDefined } = action.payload;
      state.listLoading = false;
      state.error = null;
      let dinhNghia = {
        ...state.dinhNghiaDongCot[tabDefined],
        [nameRelated]: value,
      };
      state.dinhNghiaDongCot[tabDefined] = dinhNghia;
    },

    //remove dinh nghia
    dinhNghiaDeleted: (state, action) => {
      const { nameRelated, tabDefined } = action.payload;
      state.listLoading = false;
      state.error = null;
      let dinhNghia = {
        ...state.dinhNghiaDongCot[tabDefined],
      };
      delete dinhNghia[nameRelated];
      state.dinhNghiaDongCot[tabDefined] = dinhNghia;
    },
    checkDeletedinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.checkDeletedataUpDinhNghiaDongCot = action.payload;
    },
    dinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dataUpDinhNghiaDongCot = action.payload;
    },
    deleteDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      const data = state.dataUpDinhNghiaDongCot.filter(i => i.key !== action.payload);
      state.dataUpDinhNghiaDongCot = data;
    },
    setdataChooseDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dataChooseUpDinhNghiaDongCot = [...state.dataChooseUpDinhNghiaDongCot, action.payload];
    },
    resetdataChooseDinhNghiaUpDataDongCot: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dataChooseUpDinhNghiaDongCot = [];
    },
  },
});
