/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import * as auth from './modules/Auth/_redux/authRedux';
import { connect } from 'react-redux';
// import WebWorker from './workerSetup';
import MainWorker from './main.worker';
import * as actions from '../redux/pool/poolActions';

function Routes(props) {
  const { isAuthorized, hasAuthToken, authToken } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      hasAuthToken: Boolean(auth.authToken),
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // List Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    if (hasAuthToken) {
      props.requestUser();
      const worker = new MainWorker();
      worker.postMessage({ authToken });
      worker.onmessage = message => {
        if (message) {
          console.log("Workermessage:setDataPool",message.data)
          dispatch(actions.setDataPool(message.data));
        }
      };
      return () => {
        worker.terminate();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {!isAuthorized || !hasAuthToken ? (
        /*Render auth page when user at `/uauth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/uauth" to="/" />
      )}

      {/* <Route path="/error" component={ErrorsPage} /> */}
      <Route path="/logout" component={Logout} />

      {!isAuthorized || !hasAuthToken ? (
        /*Redirect to `/uauth` when user is not authorized*/
        <Redirect to="/uauth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
export default connect(null, auth.actions)(Routes);
