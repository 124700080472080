import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

import { appBaseCRUDListSlice } from '../app/modules/AppBaseCRUD/_redux/list/listSlice';
import { poolSlice } from './pool/poolSlice';
import { appBaseCRUDListSpecsSlice } from '../app/modules/AppBaseCRUD/_redux/specifications/specificationsSlice';
import { dinhNghiaBieuMauSlice } from '../app/modules/BieuMau/_redux/bieumaukho/dinhNghiaBieuMauSlice';
import { workspaceSlice } from '../app/modules/Workspace/_redux/workspace/workspaceSlice';
import { commonQuyTaiChinhSlice } from '../app/modules/QuyTaiChinh/_redux/QuyTaiChinhSlice';
export const rootReducer = combineReducers({
  auth: auth.reducer,
  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  baseCRUDList: appBaseCRUDListSlice.reducer,
  baseCRUDListSpecs: appBaseCRUDListSpecsSlice.reducer,
  dinhNghiaBieuMau: dinhNghiaBieuMauSlice.reducer,
  workspace: workspaceSlice.reducer,
  comMonQuyTaiChinh: commonQuyTaiChinhSlice.reducer,
  pool:poolSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
