/* eslint-disable eqeqeq */
import { createSlice } from '@reduxjs/toolkit';
import { treeCustomRelatedBuild, nestedNoTree } from '../../../../../_metronic/_helpers';

const initialListState = {
  appModuleId: null,
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  treeDataPID: null,
  itemForEdit: undefined,
  lastError: null,
  config: null,
  relatedEntities: {},
  messageNotification: [],
  logUser: []
};
//callTypes list is not name object manage. name object is list
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const appBaseCRUDListSlice = createSlice({
  name: 'crudBase',
  initialState: initialListState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getItemById
    itemFetched: (state, action) => {
      state.actionsLoading = false;
      state.itemForEdit = action.payload.itemForEdit;
      state.error = null;
    },
    // findList
    listFetched: (state, action) => {
      const { totalCount, entities, config, appModuleId } = action.payload;
      state.listLoading = false;
      state.error = null;
      let dataEntities = nestedNoTree(entities, config);
      state.entities = dataEntities.entities;
      state.config = config;
      if (state.appModuleId !== appModuleId) {
        state.appModuleId = appModuleId;
        state.treeDataPID = treeCustomRelatedBuild(entities, config);
      }
      state.totalCount = totalCount;
    },

    listFetchedInPool: (state, action) => {
      const { treeDataPID, config, appModuleId } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = treeDataPID && treeDataPID.entities;
      state.config = config;
      state.appModuleId = appModuleId;
      state.treeDataPID = treeDataPID;
      state.totalCount = treeDataPID && treeDataPID.entities && treeDataPID.entities.length;
    },

    // createItem
    itemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const stateEntities = [...state.treeDataPID.entities, action.payload.item];
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // updateItem
    itemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.treeDataPID.entities.map(entity => {
        if (entity.id == action.payload.item.id) {
          return action.payload.item;
        }
        return entity;
      });
      state.treeDataPID = treeCustomRelatedBuild(state.entities, state.config);
    },
    // deleteItem
    itemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(el => el.id !== action.payload.id);
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // deleteList
    listDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const stateEntities = state.treeDataPID.entities.filter(el => !action.payload.ids.includes(el.id));
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // listUpdateState
    listStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      const stateEntities = state.treeDataPID.entities.map(entity => {
        if (ids.findIndex(id => id == entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
      state.entities = stateEntities;
      state.treeDataPID = treeCustomRelatedBuild(stateEntities, state.config);
    },
    // fetchListRelated
    listRelatedFetched: (state, action) => {
      const { entities, nameRelated } = action.payload;
      state.listLoading = false;
      state.error = null;
      let relatedEntities = {
        ...state.relatedEntities,
        [nameRelated]: entities,
      };
      state.relatedEntities = relatedEntities;
    },
    setMessageNotification: (state, action) => {
      state.messageNotification = [
        ...state.messageNotification,
        ...action.payload.filter(item => state.messageNotification.findIndex(_item => _item.uuid == item.uuid) < 0),
      ];
    },
    reloadMessageNotification: (state, action) => {
      state.messageNotification = action.payload;
    },
    listFetchedLog: (state, action) => {
      console.log(`action`, action.payload)
      const { list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.logUser = list;
    },
  },
});
