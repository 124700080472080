import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalProgressBar } from '../_partials/controls';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormHelpers } from './FormHelpers';
import { v4 as uuidv4 } from 'uuid';

const buildObjectFromAppModuleConfig = defObjectProps => {
  let obj = {
    init: {},
    field: [],
  };
  for (let i = 0; i < defObjectProps.length; i++) {
    //nếu là legend thì lấy các con của legend
    let itemP = defObjectProps[i];
    if (itemP.hasOwnProperty('propsData')) {
      for (let ji = 0; ji < itemP.propsData.length; ji++) {
        let item = itemP.propsData[ji];
        obj.init[item.dataField] = item.defaultValue;
        if (!item.disableWhenUpdate) {
          obj.field.push(item);
        }
      }
    } else {
      //nếu ko phải legend
      obj.init[itemP.dataField] = itemP.defaultValue;
      if (!itemP.disableWhenUpdate) {
        obj.field.push(itemP);
      }
    }
  }
  return obj;
};

export default function ModalHelpers({
  modalTitle,
  dataAndConfig,
  show,
  onHide,
  isLoading,
  uuidKeyCode,
  isEditForm,
  saveItem,
  relatedEntities,
  buttonMore
}) {
  const intl = useIntl();
  const initItem = buildObjectFromAppModuleConfig(dataAndConfig);
  const formHelper = new FormHelpers(intl, uuidKeyCode, isEditForm, relatedEntities);
  const buildFieldRender = formHelper.buildFieldFromAppModuleConfig({ defObjectProps: initItem.field, numFieldInRow: 1 });
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg" className={uuidKeyCode}>
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={initItem.init}
        validationSchema={Yup.object().shape(buildFieldRender.validate)}
        onSubmit={values => {
          saveItem(values);
        }}
      >
        {({ handleSubmit }) => (
          <Form className="form form-label-right">
            <Modal.Body>
              {buildFieldRender.fieldRender.map((component, key) => (
                <React.Fragment key={component.key + '_line'}>{component}</React.Fragment>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                  {intl.formatMessage({ id: 'GENERAL.CRUD.CANCEL' })}
                </button>
                <> </>
                <button type="button" onClick={handleSubmit} className="btn btn-primary btn-elevate">
                  {intl.formatMessage({ id: 'GENERAL.CRUD.SAVE' })}
                </button>
                {
                  buttonMore && buttonMore.map((component,key)=><React.Fragment key={uuidv4()}><> </>{component}</React.Fragment>)
                }
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
