import * as requestFromServer from "./listCrud";
import { appBaseCRUDListSlice, callTypes } from "./listSlice";
import { treeCustomRelatedBuild, buildDataCRUD } from "../../../../../_metronic/_helpers";

const { actions } = appBaseCRUDListSlice;

export const fetchList = (baseServiceUrl, config, queryParams, appModuleId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findList(baseServiceUrl, queryParams)
    .then(response => {
      const { totalCount, entities } = response.data.result;
      dispatch(actions.listFetched({ totalCount, entities, config, appModuleId }));
    })
    .catch(error => {
      error.clientMessage = "Can't find list";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// export const fetchListInPool = (treeDataPID, config, appModuleId) => dispatch => {
//   dispatch(actions.listFetchedInPool({ treeDataPID, config, appModuleId }));
// };

export const fetchItem = (baseServiceUrl, id) => dispatch => {
  if (!id) {
    return dispatch(actions.itemFetched({ itemForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getItemById(baseServiceUrl, id)
    .then(response => {
      const item = response.data.result.item;
      dispatch(actions.itemFetched({ itemForEdit: item }));
    })
    .catch(error => {
      error.clientMessage = "Can't find item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getLogByUsername = (baseServiceUrl, username, limitRecord) => dispatch => {

  let itemRequest = {
    username,
    limitRecord
  };
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLogByUsername(baseServiceUrl, itemRequest)
    .then(response => {

      const item = response.data.result.entities;
      dispatch(actions.listFetchedLog({ list: item }));
    })
    .catch(error => {
      error.clientMessage = "Can't find item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteItem = (baseServiceUrl, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteItem(baseServiceUrl, id)
    .then(response => {
      dispatch(actions.itemDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createItem = (baseServiceUrl, itemForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let itemRequest = buildDataCRUD(itemForCreation);
  return requestFromServer
    .createItem(baseServiceUrl, itemRequest)
    .then(response => {
      const { item } = response.data.result;
      dispatch(actions.itemCreated({ item }));
    })
    .catch(error => {
      error.clientMessage = "Can't create item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateItem = (baseServiceUrl, item) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let itemRequest = buildDataCRUD(item);
  return requestFromServer
    .updateItem(baseServiceUrl, itemRequest)
    .then(() => {
      dispatch(actions.itemUpdated({ item: itemRequest }));
    })
    .catch(error => {
      error.clientMessage = "Can't update item";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateListStatus = (baseServiceUrl, ids, status) => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  //   .updateStatusForList(baseServiceUrl,ids, status)
  //   .then(() => {
  //     dispatch(actions.listStatusUpdated({ ids, status }));
  //   })
  //   .catch(error => {
  //     error.clientMessage = "Can't update list status";
  //     dispatch(actions.catchError({ error, callType: callTypes.action }));
  //   });
};

export const deleteList = (baseServiceUrl, ids) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteList(baseServiceUrl, ids)
    .then(() => {
      dispatch(actions.listDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete list";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// fetchListRelated dùng để lấy dữ liệu các danh mục liên quan tới item hiện tại để chọn ở select box, bảng...
export const fetchListRelated = (nameRelated, url, config, queryParams = {}) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findListRelated(url, queryParams)
    .then(response => {
      const { entities } = response.data.result;
      const treeData = treeCustomRelatedBuild(entities, config);
      dispatch(actions.listRelatedFetched({ entities: treeData, nameRelated }));
    })
    .catch(error => {
      error.clientMessage = `Can't find list related: ${nameRelated}`;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchListRelatedInPool = (nameRelated, data) => dispatch => {
  dispatch(actions.listRelatedFetched({ entities: JSON.parse(data), nameRelated }));
};

export const setMessageNotification = data => dispatch => {
  dispatch(actions.setMessageNotification(data));
};
export const reloadMessageNotification = data => dispatch => {
  dispatch(actions.reloadMessageNotification(data));
};
//không xóa phần này
export const logsystemdeletefixed = (url)=> dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .logsystemdeletefixed(url)
    .then(response => {

    })
    .catch(error => {
      error.clientMessage = `Không thể xóa`;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

