import axios from "axios";
import { treeCustomRelated, nestedNoTree } from "../../../../_metronic/_helpers";

export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "/api/auth/register";
export const REQUEST_PASSWORD_URL = "/api/auth/forgot-password";
export const LOGOUT_URL = "/auth/logout";

export const ME_URL = "/services/uaa/api/account";

export const WORKSPACE_URL = "/services/qtht/api/ws/ds";
export const TYPE_WS = "DUTOAN";

export function login(email, password) {
  return axios.post(LOGIN_URL, { username: email, password })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function logout() {
  return axios.post(LOGOUT_URL);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
function fetchListRelated(CUSTOM_URL, queryParams= {}) {
  return axios.get(CUSTOM_URL, { queryParams });
}
export function getLisWStByType(year, month) {
  return axios.post(`${WORKSPACE_URL}/${TYPE_WS}`, { nam: year, thang: month });
}

export function fetchListRelatedApp() {
  return Promise.all([
    // fetchListRelated("/services/qtht/api/danhmuc/chuong"),
    // fetchListRelated("/services/qtht/api/danhmuc/loaikhoan"),
    // fetchListRelated("/services/qtht/api/danhmuc/muctieumuc"),
    // fetchListRelated("/services/qtht/api/danhmuc/chuongtrinhmtqg"),
    // fetchListRelated("/services/qtht/api/danhmuc/nguon"),
    // fetchListRelated("/services/qtht/api/danhmuc/tieuchicot"),
    // fetchListRelated("/services/qtht/api/danhmuc/tieuchidong"),
    // fetchListRelated("/services/uaa/api/organizations"),
    // fetchListRelated("/services/uaa/api/phongban"),
    fetchListRelated("/services/uaa/api/nhomnguoidung"),
    // fetchListRelated("/services/uaa/api/users"),
    getLisWStByType(new Date().getFullYear(), new Date().getMonth() + 1)
  ]).then(([
    // chuong,
    // loaiKhoan,
    // mucTieuMuc,
    // chuongTrinhDuAn,
    // nguon,
    // tieuChiCot,
    // tieuChiDong,
    // coQuan,
    // phongBan,
    nhomNguoiDung,
    // nguoiDung,
    workspace
  ]) => {
    return ({
      // "chuong":nestedNoTree(chuong.data.result.entities,{cidProp: "id", nameProp:"ten", codeProp:"ma"}),
      // "loaiKhoan":treeCustomRelated(loaiKhoan.data.result.entities),
      // "mucTieuMuc":treeCustomRelated(mucTieuMuc.data.result.entities),
      // "chuongTrinhDuAn":treeCustomRelated(chuongTrinhDuAn.data.result.entities),
      // "nguon":treeCustomRelated(nguon.data.result.entities),
      // "tieuChiCot":treeCustomRelated(tieuChiCot.data.result.entities),
      // "tieuChiDong":treeCustomRelated(tieuChiDong.data.result.entities),
      // "coQuan":treeCustomRelated(coQuan.data.result.entities, {cidProp: "id", pidProp: "pid", nameProp:"tencoquan", codeProp:"macoquan"}),
      // "phongBan":nestedNoTree(phongBan.data.result.entities, {cidProp: "id", nameProp:"tenphongban", codeProp:"maphongban"}),
      "nhomNguoiDung":nestedNoTree(nhomNguoiDung.data.result.entities, {cidProp: "id", nameProp:"tennhomnguoidung", codeProp:"manhomnguoidung"}),
      // "nguoiDung":nestedNoTree(nguoiDung.data.result.entities, {cidProp: "id", nameProp:[ "lastName", "firstName" ]}),
      "workSpace":workspace.data.result,
    })
  }).catch(reason => {
    console.log(reason)
  });
}
