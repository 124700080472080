import React from 'react';
import { useField, useFormikContext } from 'formik';
import { IntelligentTreeSelect } from './IntelligentTreeSelect/IntelligentTreeSelect';
import './IntelligentTreeSelect/lib/styles.css';

export function TreeSelectFieldMulti({ asteriskSign, ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const checkValueInttoObject = choose => {
    let out = [];
    if (Array.isArray(choose)) {
      for (var node of choose) {
        let nodeOut = node;
        if (typeof node != 'object') {
          nodeOut = props.listTree && props.listTree.entiObj && props.listTree.entiObj[node];
        }
        out.push(nodeOut);
      }
    } else {
      let nodeOut = choose;
      if (typeof choose != 'object') {
        nodeOut = props.listTree && props.listTree.entiObj && props.listTree.entiObj[choose];
      }
      out.push(nodeOut);
    }
    return out;
  };
  // console.log((field.value && checkValueInttoObject(field.value)) || [])

  const handleOnChangeMultiple = val => {
    setFieldValue(field.name, val);
  };
  const dynamicProps = props.isMenuOpen ? { minHeight: 300 } : {};

  const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control'];
    if (touched && errors && !props.isMenuOpen) {
      classes.push('is-invalid');
    }

    if (touched && !errors && !props.isMenuOpen) {
      classes.push('is-valid');
    }

    return classes.join(' ');
  };

  return (
    <>
      {props.label && (
        <label>
          {props.label} {asteriskSign && <i style={{ color: 'red' }}>*</i>}
        </label>
      )}
      <IntelligentTreeSelect
        className={`crnx-select ${props.isMenuOpen && `crnx-select-alwayshow`} ${getFieldCSSClasses(
          touched[field.name],
          errors[field.name]
        )}`}
        style={{ width: '100%' }}
        {...props}
        {...dynamicProps}
        value={(field.value && checkValueInttoObject(field.value)) || []}
        onChange={handleOnChangeMultiple}
        expanded={true}
        valueKey={(props.listTree && props.listTree.valueKey) || 'id'}
        labelKey={'title'}
        childrenKey={'children'}
        simpleTreeData={true}
        showSettings={false}
        optionHeight={36}
        options={(props.listTree && props.listTree.entiObj && Object.values(props.listTree.entiObj)) || []}
        // displayInfoOnHover={true}
        placeholder={props.placeholder ? props.placeholder : props.label}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
      ) : null}
      {/* <div className="feedback">
        Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
      </div> */}
    </>
  );
}
