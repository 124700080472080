import React, {useMemo} from "react";
// import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import SectionMenu from "./item/SectionMenu";
import ItemMenu from "./item/ItemMenu";
import AsideMenuConfig from "../../../../../config/appModules/AsideMenuConfig";
import {hasChildren} from "../../../../_helpers";
import { v4 as uuidv4 } from 'uuid';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPhanQuyenChucNang } from '../../../../../config/phanquyen'

export function AsideMenu({disableScroll}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);
  const {user, nhomnguoidung} = useSelector(
    ({auth}) => ({
      user: auth.user,
      nhomnguoidung: auth.relatedEntities.nhomNguoiDung
    }),
    shallowEqual
  );

  const addChildCountFn = (node) =>{
    let components = []
    if(hasChildren(node)){
      node.children.map((itemChild,keyChild) => components.push(addChildCountFn(itemChild)))
    }
    if(node.type === "SECTION"){
      return (
      <React.Fragment>
        <SectionMenu name={node.name}></SectionMenu>
        {components.map((component, key) => (<React.Fragment key={uuidv4()}>{component}</React.Fragment>))}
      </React.Fragment>)
    }else{
      let urlSplit = (node.url).split("/")
      let keyId = (urlSplit[urlSplit.length - 1]).toUpperCase()
      let dynamicProps = node.svgIcon ? {svgIcon : node.svgIcon}: {}
      return (
        (keyId === "THTCNSNN" || keyId == "QUYETTOAN" || keyId === 'DUTOAN' || checkPhanQuyenChucNang(node.url, user, "", nhomnguoidung)) && (
          <ItemMenu url={node.url} name={node.name} {...dynamicProps}>
            {components.map((component, key) => (<React.Fragment key={uuidv4()}>{component}</React.Fragment>))}
          </ItemMenu>
        )
      )
    }
  }

  const asideMenuList = ()=>{
    let asideMenuList = [];
    let asideMenuConfig = AsideMenuConfig();
    asideMenuConfig.map((itemChild,keyChild) => asideMenuList.push(addChildCountFn(itemChild)))
    return asideMenuList
  }

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {/* <AsideMenuList layoutProps={layoutProps} /> */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {asideMenuList().map((component, key) => (<React.Fragment key={uuidv4()}>{component}</React.Fragment>))}
        </ul>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
